import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEmployeeDataStore } from '../../../hooks/useDataStore';
import { RadialBar } from '../charts';
import styles from '../styles';

export default function Chart1() {
  const chartData = useEmployeeDataStore((state) => state.chartData1);

  return (
    <Container maxWidth={false} sx={styles.entry}>
      <Stack direction="row" sx={{ alignSelf: 'start' }}>
        <Typography variant="h3" id="squad-size">
          The size of each squad
        </Typography>
        <Link href="#squad-size">
          <IconButton aria-label="link to this chart" color="secondary">
            <LinkIcon fontSize="large" />
          </IconButton>
        </Link>
      </Stack>
      <Container maxWidth={false} sx={styles.chart}>
        {typeof chartData !== 'undefined' && Object.keys(chartData).length > 0
          ? <RadialBar data={chartData} />
          : <Skeleton variant="circular" width={600} height={600} />}
      </Container>
      <Paper elevation={4} sx={styles.information}>
        This shows the squad size.
      </Paper>
    </Container>
  );
}
