import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Navigation from './Navigation';
import PermissionsGate from '../PermissionsGate';
import { SCOPES } from '../../constants/permission-maps.js';
import { isMobile } from '../../constants/userDevice.js';
import useAuthStore from '../../hooks/useAuthStore';
import useSiteStore from '../../hooks/useSiteStore';

const styles = {
  layoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
};

export default function Layout() {
  const logout = useSiteStore((state) => state.logout);
  const printMode = useSiteStore((state) => state.printMode);
  const role = useAuthStore((state) => state.role);
  const screenID = useSiteStore((state) => state.screenID);
  const setPrintMode = useSiteStore((state) => state.setPrintMode);
  const setScreenID = useSiteStore((state) => state.setScreenID);

  const [showSnackbar, setShowSnackbar] = useState(role === 'VIEWER');

  const pathname = useLocation().pathname.replace('/', '');
  const navigate = useNavigate();

  const togglePrintMode = () => {
    setShowSnackbar(true);
    navigate('schedule');
    setPrintMode(true);
  };

  useEffect(() => {
    setScreenID(pathname);
  }, [pathname, setScreenID]);

  useEffect(() => {
    let timeout;
    let lastTap = 0;

    function windowKeyListener(event) {
      if (event.keyCode === 27 && screenID === 'schedule') {
        if (role === 'VIEWER') {
          logout();
        } else {
          setPrintMode(false);
        }
      }
    }

    function windowTouchListener(event) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTap;

      clearTimeout(timeout);

      if (tapLength < 500 && tapLength > 0) {
        if (role === 'VIEWER') {
          logout();
        } else {
          setPrintMode(false);

          // Double tapping may select text. Deselect that text.
          window.getSelection()?.removeAllRanges();
        }

        event.preventDefault();
      } else {
        timeout = setTimeout(function() {
          clearTimeout(timeout);
        }, 500);
      }

      lastTap = currentTime;
    }

    if (printMode) {
      // Register the listeners that will turn off print mode.
      window.addEventListener('keydown', windowKeyListener);
      window.addEventListener('touchend', windowTouchListener);
    }

    return () => {
      if (printMode) {
        window.removeEventListener('keydown', windowKeyListener);
        window.removeEventListener('touchend', windowTouchListener);
      }
    }
  }, [logout, printMode, role, screenID, setPrintMode]);

  return (
    <div className="layout-container" style={styles.layoutContainer}>
      <PermissionsGate scopes={[SCOPES.canEdit, SCOPES.canCreate]}>
        <Navigation togglePrintMode={togglePrintMode} />
      </PermissionsGate>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        onClose={() => { setShowSnackbar(false) }}
        message={`${isMobile ? 'Double tap' : 'Press ESCAPE'} to ${role === 'VIEWER' ? 'log out' : 'return'}.`}
        key="bottomleft"
      />
      <Outlet />
    </div>
  );
}
