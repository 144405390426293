import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const styles = {
  tablePaper: {
    flexGrow: 1,
    marginBottom: '56px',
    overflowY: 'hidden',
    width: '100%',
  },
  tableContainer: {
    maxHeight: '100%',
  },
};

function descendingComparator(a, b, orderBy) {
  let aValue = a[orderBy] === null ? '' : a[orderBy];
  let bValue = b[orderBy] === null ? '' : b[orderBy];

  if (typeof aValue === 'object') {
    aValue = aValue.props.title;
  }

  if (typeof bValue === 'object') {
    bValue = bValue.props.title;
  }

  if (bValue < aValue) {
    return -1;
  }

  if (bValue > aValue) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function formatValue(column, value) {
  let formatted = value;

  if (typeof column.format === 'function') {
    formatted = column.format(value);
  }

  return formatted;
}

export default function BuildTable(props) {
  const {
    apiAddress,
    columns,
    createRows,
    handleEdit,
    primaryKey,
    setLoading,
    setError,
  } = props;
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  useEffect(() => {
    setError(false);
    setLoading(true);

    async function fetchRecords() {
      return fetch(apiAddress.fetchRecords)
        .then(data => data.json())
        .then((data) => {
          if (data.error) {
            console.error(data.errorMessage);
            setError(data.errorMessage);
          } else {
            setRows(createRows(data.body));
          }

          setLoading(false);
        });
    }

    fetchRecords();
  }, [apiAddress.fetchRecords, createRows, setError, setLoading]);

  const createSortHandler = (property) => (event) => {
    // const isAsc = orderBy === property && order === 'asc';
    let direction = 'asc';

    if (orderBy === property) {
      if (order === 'asc') {
        direction = 'desc';
      } else if (order === 'desc') {
        direction = '';
      } else if (order === '') {
        direction = 'asc';
      }
    }

    setOrder(direction);
    setOrderBy(direction === '' ? '' : property);
  };

  return (
    <Paper elevation={0} sx={styles.tablePaper}>
      <TableContainer sx={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice().sort(getComparator(order, orderBy)).map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row[primaryKey]}
                  onClick={() => handleEdit(row[primaryKey])}
                  sx={{ cursor: 'pointer' }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell key={column.id} align={column.align}>
                        {formatValue(column, value)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
