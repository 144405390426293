import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import api from '../../constants/api';
import useAuthStore from '../../hooks/useAuthStore';

const styles = {
  helpIconContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  helpIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    '&:hover': {
      color: (theme) => theme.palette.primary.main,
    },
  },
  helpContainer: {
    padding: '1em',
    position: 'relative',
    margin: '0 3em 4em 3em',
  },
  formContainer: {
    padding: '1em',
    position: 'relative',
    marginBottom: '4em',
  },
  loading: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
  stacks: {
    marginBottom: '1em',
  },
  textField: {
    width: '227px',
  },
  submitRow: {
    display: 'flex',
    marginTop: '1em',
  },
};

export default function Form({ formData, setView, recordData }) {
  const authPackage = useAuthStore((state) => state.getAuthPackage);

  const squads = formData.squads ?? [];
  const ranks = formData.ranks ?? [];
  const newRecord = typeof recordData === 'undefined';

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [pdid, setPDID] = useState('');
  const [unit, setUnit] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [radio, setRadio] = useState('');
  const [rankID, setRankID] = useState(0);
  const [squadID, setSquadID] = useState(0);
  const [active, setActive] = useState(1);
  const [badge, setBadge] = useState('');
  const [cityStartDate, setCityStartDate] = useState(null);
  const [parkingStartDate, setParkingStartDate] = useState(null);
  const [parkingEndDate, setParkingEndDate] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [helpVisible, setHelpVisibility] = useState(false);

  useEffect(() => {
    if (!newRecord && typeof recordData.employeeID !== 'undefined') {
      console.debug('Filling the existing employee data.');

      setFirstname(recordData.firstname);
      setLastname(recordData.lastname);
      setPDID(recordData.pdid);
      setUnit(recordData.unit !== null ? recordData.unit : '');
      setVehicle(recordData.vehicle !== null ? recordData.vehicle : '');
      setRadio(recordData.radio !== null ? recordData.radio : '');
      setRankID(recordData.rankID);
      setSquadID(recordData.squadID === null ? 0 : recordData.squadID);
      setActive(recordData.active);
      setBadge(recordData.badge !== null ? recordData.badge : '');
      setCityStartDate(recordData.cityStartDate !== null ? `${recordData.cityStartDate}T17:00:00.000-07:00` : '');

      console.debug(`The parking start date is ${recordData.parkingStartDate}.`);
      console.debug(`The parking end date is ${recordData.parkingEndDate}.`);

      setParkingStartDate(recordData.parkingStartDate !== null ? `${recordData.parkingStartDate}T17:00:00.000-07:00` : '');
      setParkingEndDate(recordData.parkingEndDate !== null ? `${recordData.parkingEndDate}T17:00:00.000-07:00` : '');
    }
  }, [recordData, newRecord]);

  const handleChangeFirstname = (event) => {
    setFirstname(event.target.value);
  }

  const handleChangeLastname = (event) => {
    setLastname(event.target.value);
  }

  const handleChangePDID = (event) => {
    setPDID(event.target.value);
  }

  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  }

  const handleChangeVehicle = (event) => {
    setVehicle(event.target.value);
  }

  const handleChangeRadio = (event) => {
    setRadio(event.target.value);
  }

  const handleChangeRankID = (event) => {
    setRankID(event.target.value);
  }

  const handleChangeSquadID = (event) => {
    setSquadID(event.target.value);
  }

  const handleChangeActive = (event) => {
    setActive(event.target.value);
  };

  const handleChangeBadge = (event) => {
    setBadge(event.target.value);
  };

  const handleChangeCityStartDate = (newValue) => {
    setCityStartDate(newValue);
  };

  const handleChangeParkingStartDate = (newValue) => {
    setParkingStartDate(newValue);
  };

  const handleChangeParkingEndDate = (newValue) => {
    setParkingEndDate(newValue);
  };

  const handleSubmit = () => {
    // Show the loading animation to prevent further values from changing.
    setLoading(true);

    const apiAddress = newRecord ? `${api.address}/employee/create.php` : `${api.address}/employee/update.php`;
    const values = {
      firstname,
      lastname,
      pdid,
      unit,
      vehicle,
      radio,
      rankID,
      squadID,
      active,
      badge,
      cityStartDate,
      parkingStartDate,
      parkingEndDate,
      token: authPackage(),
    };

    if (!newRecord) {
      // Add the squad ID if updating a record.
      values.employeeID = recordData.employeeID;
    }

    fetch(apiAddress, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  };

  const handleDelete = () => {
    console.debug(`The user wants to delete employee ${recordData.employeeID}`);

    setLoading(true);

    fetch(`${api.address}/employee/delete.php?employeeID=${recordData.employeeID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  }

  const showHelp = () => {
    setHelpVisibility(!helpVisible);
  };

  return (
    <>
      <Paper elevation={2} sx={styles.formContainer}>
        <Backdrop open={isLoading} sx={styles.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {errorMessage !== '' ? <Alert severity="warning" sx={{ marginBottom: '1em' }}>{errorMessage}</Alert> : null}
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            required
            id="input-firstname"
            label="First name"
            variant="outlined"
            value={firstname}
            onChange={handleChangeFirstname}
            sx={styles.textField}
          />
          <TextField
            required
            id="input-lastname"
            label="Last name"
            variant="outlined"
            value={lastname}
            onChange={handleChangeLastname}
            sx={styles.textField}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            required
            id="input-pdid"
            label="PDID"
            variant="outlined"
            value={pdid}
            onChange={handleChangePDID}
            sx={styles.textField}
          />
          <TextField
            id="input-unit"
            label="Unit call sign"
            variant="outlined"
            value={unit}
            onChange={handleChangeUnit}
            sx={styles.textField}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            id="input-vehicle"
            label="Vehicle"
            variant="outlined"
            value={vehicle}
            onChange={handleChangeVehicle}
            sx={styles.textField}
          />
          <TextField
            id="input-radio"
            label="Radio"
            variant="outlined"
            value={radio}
            onChange={handleChangeRadio}
            sx={styles.textField}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <Box sx={{ minWidth: 146 }}>
            <FormControl required fullWidth>
              <InputLabel id="select-rank-label">Rank</InputLabel>
              <Select
                labelId="select-rank-label"
                id="select-rank"
                value={rankID}
                label="Rank"
                onChange={handleChangeRankID}
              >
                <MenuItem value={0}>None</MenuItem>
                {ranks.map((rank) => {
                  return (
                    <MenuItem key={rank.rankID} value={rank.rankID}>
                      {rank.rankDescriptionShort}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 146 }}>
            <FormControl fullWidth>
              <InputLabel id="select-squad-label">Squad</InputLabel>
              <Select
                labelId="select-squad-label"
                id="select-squad"
                value={squadID}
                label="Squad"
                onChange={handleChangeSquadID}
              >
                <MenuItem value={0}>None</MenuItem>
                {squads.map((squad) => {
                  return (
                    <MenuItem key={squad.squadID} value={squad.squadID}>
                      {squad.callSignGroup === null ? squad.squadOrder : `${squad.callSignGroup} (${squad.squadOrder})`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 146 }}>
            <FormControl fullWidth>
              <InputLabel id="select-active-label">Active</InputLabel>
              <Select
                labelId="select-active-label"
                id="select-active"
                value={active}
                label="Active"
                onChange={handleChangeActive}
                required
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Divider sx={{ marginBottom: '1rem' }} />
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            id="input-badge"
            label="Badge"
            variant="outlined"
            value={badge}
            type="number"
            onChange={handleChangeBadge}
            sx={styles.textField}
          />
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            dateFormats={{
              normalDate: "yyyy-MM-dd",
              keyboardDate: "yyyy-MM-dd",
            }}
          >
            <DatePicker
              label="City start date"
              value={cityStartDate}
              onChange={handleChangeCityStartDate}
              renderInput={(params) => <TextField {...params} error={false} sx={styles.textField} />}
              // renderInput={(params) => console.log(params)}
              disableMaskedInput
            />
          </LocalizationProvider>
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            dateFormats={{
              normalDate: "yyyy-MM-dd",
              keyboardDate: "yyyy-MM-dd",
            }}
          >
            <DatePicker
              label="Parking start date"
              value={parkingStartDate}
              onChange={handleChangeParkingStartDate}
              renderInput={(params) => <TextField {...params} error={false} sx={styles.textField} />}
              disableMaskedInput
            />
            <DatePicker
              label="Parking end date"
              value={parkingEndDate}
              onChange={handleChangeParkingEndDate}
              renderInput={(params) => <TextField {...params} error={false} sx={styles.textField} />}
              disableMaskedInput
            />
          </LocalizationProvider>
        </Stack>
        <Box sx={styles.submitRow}>
          {!newRecord ? <Button variant="outlined" onClick={handleDelete} color="error">Delete</Button> : null}
          <div style={styles.helpIconContainer}>
            <Tooltip title="View help">
              <HelpOutlineIcon onClick={showHelp} fontSize="large" sx={styles.helpIcon} alt="Open help" />
            </Tooltip>
          </div>
          <Button variant="outlined" onClick={handleSubmit}>Save</Button>
        </Box>
      </Paper>
      <Paper elevation={2} sx={styles.helpContainer} style={{ display: helpVisible ? 'block' : 'none' }}>
        <Typography variant="h5">Name</Typography>
        <Typography variant="body1" paragraph>
          The information here is what will be used to build the schedule.
          User proper spelling and capitalization.
        </Typography>
        <Typography variant="h5">PDID</Typography>
        <Typography variant="body1" paragraph>
          Every employee has a PDID.
        </Typography>
        <Typography variant="h5">Unit, vehicle, radio</Typography>
        <Typography variant="body1" paragraph>
          These are optional because not every employee on the schedule has been assigned a unit call sign, vehicle, or radio.
        </Typography>
        <Typography variant="h5">Squad</Typography>
        <Typography variant="body1" paragraph>
          Every employee should be assigned to a squad except for the senior parking enforcement supervisor.
          Employees who are dispatchers, assigned to industrial duty, or are performing administrative
          duties should be placed in the "administration" squad.
          Light duty employees should not be assigned to the "administration" squad unless their
          light duty status is permanent and they no longer have a unit call sign.
          Light duty employees will show up in their regular squads with the "light duty" status.
        </Typography>
        <Typography variant="h5">Active</Typography>
        <Typography variant="body1" paragraph>
          Creating a new employee that is active will insert that employee on todays' schedule.
          If you are creating an employee whose first day is in the future: set that employee to active on their first day.
        </Typography>
      </Paper>
    </>
  );
}
