const formatInput = (string, options) => {
  const {
    hoursField,
    numberField,
    noteField,
    trailing,
  } = options || {};

  let formatted = string;
  let allowedCharacters = /[^\w\s-.]/g;

  if (noteField) {
    allowedCharacters = /[^\w\s/@&()-.]/g;
  } else if (numberField) {
    allowedCharacters = /[^\d]/g;
  } else if (hoursField) {
    allowedCharacters = /[^\d-]/g;
  }

  // Remove unwanted characters.
  formatted = formatted.replace(allowedCharacters, '');

  if (numberField) {
    return formatted;
  }

  // Set the user input to upper case. Do this first so the word replacements match later on.
  formatted = formatted.toUpperCase();

  // Replace multiple spaces with a single space.
  formatted = formatted.replace(/\s\s+/g, ' ');

  // Replace multiple forward slashes with single forward slashes.
  formatted = formatted.replace(/\/\/+/g, '/');

  // Remove leading spaces.
  formatted = formatted.replace(/^\s+/g, '');

  // Replace multiple dashes with a single dash.
  formatted = formatted.replace(/--+/g, '-');

  // Replace space padded forward slashes with just the forward slash.
  formatted = formatted.replace(/(\w) \/ (\w)/, '$1/$2');
  formatted = formatted.replace(/(\w)\/ (\w)/, '$1/$2');
  formatted = formatted.replace(/(\w) \/(\w)/, '$1/$2');

  // Remove the trailing 's' from after three numbers.
  formatted = formatted.replace(/(\d{3})s/g, '$1');

  // Replace "W/" with "WITH".
  formatted = formatted.replace(/\sW\/(\w)/g, ' WITH $1');
  formatted = formatted.replace(/\sW\s\/\s(\w)/g, ' WITH $1');
  formatted = formatted.replace(/\sW-|\sW\//g, ' WITH');
  formatted = formatted.replace(/WITH\//g, 'WITH ');
  formatted = formatted.replace(/WITH(\d{1})/g, 'WITH $1');
  formatted = formatted.replace(/W(\d{4})P/g, 'WITH $1P');

  // Replace common mistakes.
  formatted = formatted.replace(/ASST/g, 'ASSIST');
  formatted = formatted.replace(/HGTS|HGTHS|HIGHTS|HEGHTS|HEIGETS|HIEGHTS|HIGHTS/g, 'HEIGHTS');
  formatted = formatted.replace(/HEILLS|HIILLS|HIILS/g, 'HILLS');
  formatted = formatted.replace(/MILIITARY/g, 'MILITARY');
  formatted = formatted.replace(/MISION|MISISON|MISSIONS|MISSISON|MISSON|MISSSION|MSSION/g, 'MISSION');
  formatted = formatted.replace(/NORMARL/g, 'NORMAL');
  formatted = formatted.replace(/NORHT|NORTRH|NOTH/g, 'NORTH');
  formatted = formatted.replace(/OCEACN|OCENA|OEACN|OEAN/g, 'OCEAN');
  formatted = formatted.replace(/PACFIC|PACFIFIC|PACIC|PACIFICE|PAICFIC/g, 'PACIFIC');
  formatted = formatted.replace(/PRMT/g, 'PERMIT');
  formatted = formatted.replace(/PLAZE/g, 'PLAZA');
  formatted = formatted.replace(/TOIWN/g, 'TOWN');
  formatted = formatted.replace(/TRNG|TRNIG|TRAING|TRAINGING|TRAINNG|TRAINNING/g, 'TRAINING');
  formatted = formatted.replace(/TRAINING\//g, 'TRAINING ');
  formatted = formatted.replace(/ TIL /g, ' UNTIL ');
  formatted = formatted.replace(/UVSTY|UNVIRSITY|UNIVSRSITY|UNIVRSITY|UNIVIERSITY|UNICERSITY|UNIIVERSITY|UNIVERISITY|UNIVERISTY|UNIVERITY|UNIVERSTIY|UNIVESITY|UNVERSITY|UINVERSITY|UNIVERCITY|UNIVERSITHY|UNIVERSIYT|UNIVERSTY/g, 'UNIVERSITY');

  // Replace cardinal directions.
  formatted = formatted.replace(/^N\s/g, 'NORTH ');
  formatted = formatted.replace(/^S\s/g, 'SOUTH ');
  formatted = formatted.replace(/^E\s/g, 'EAST ');
  formatted = formatted.replace(/^W\s/g, 'WEST ');
  formatted = formatted.replace(/W LOT/g, 'WEST LOT');
  formatted = formatted.replace(/E LOT/g, 'EAST LOT');
  formatted = formatted.replace(/N ENTRANCE/g, 'NORTH ENTRANCE');
  formatted = formatted.replace(/S ENTRANCE/g, 'SOUTH ENTRANCE');
  formatted = formatted.replace(/N\sOF\s|N.\sOF\s|NO.\sOF\s/g, 'NORTH OF ');
  formatted = formatted.replace(/S\sOF\s|S.\sOF\s|SO.\sOF\s/g, 'SOUTH OF ');
  formatted = formatted.replace(/N E|NORTH-EAST/g, 'NORTH EAST');
  formatted = formatted.replace(/N W|NORTH-WEST/g, 'NORTH WEST');
  formatted = formatted.replace(/SE\s|SE-|SOUTHEAST|SOUTH-EAST/g, 'SOUTH EAST');
  formatted = formatted.replace(/SW\s|SW-|SOUTHTHWEST|SOUTHWEST|SOUTH-WEST/g, 'SOUTH WEST');

  // Replace abbreviated or misspelled assignments.
  formatted = formatted.replace(/5TH-N/g, '5TH NORTH');
  formatted = formatted.replace(/5TH-S/g, '5TH SOUTH');
  formatted = formatted.replace(/5TH NE/g, '5TH NORTH EAST');
  formatted = formatted.replace(/5TH NW/g, '5TH NORTH WEST');
  formatted = formatted.replace(/BABOLA/g, 'BALBOA');
  formatted = formatted.replace(/BALBOA$|BALBOAPARK/g, 'BALBOA PARK');
  formatted = formatted.replace(/BEACHS/g, 'BEACHES');
  formatted = formatted.replace(/CITY\sWIDE/g, 'CITYWIDE');
  formatted = formatted.replace(/CLMT|CLAIRMONT|CLAIRMENONT|CLAIREMONTMEST|CLAIREMENT|CLAIERMONT/g, 'CLAIREMONT');
  formatted = formatted.replace(/CLOUMBIA|COLMBIA|COLUBIA|COLUBMIA|COLUMBA|COLUMBIOA|COLUMIA|COLUMPBIA|COMUMBIA|COULMBIA|COULUMBIA/g, 'COLUMBIA');
  formatted = formatted.replace(/COLUMBIA[\s-]{0,}N$/g, 'COLUMBIA NORTH');
  formatted = formatted.replace(/COLUMBIA[\s-]{0,}S$/g, 'COLUMBIA SOUTH');
  formatted = formatted.replace(/CORECOLUMBIA/g, 'CORE COLUMBIA');
  formatted = formatted.replace(/DEANZA/g, 'DE ANZA');
  formatted = formatted.replace(/DWNTWN|DWNTOWN|DOWN TOWN/g, 'DOWNTOWN');
  formatted = formatted.replace(/EASTER /g, 'EASTERN');
  formatted = formatted.replace(/GASLAMPM|GASLMAP|GASLPAMP|GASMLAMP|GASS LAMP|GASSLAMP|GSALAMP/g, 'GASLAMP');
  formatted = formatted.replace(/GLIDDER/g, 'GLIDER');
  formatted = formatted.replace(/GOLDEN HILLS|GOLDENHILL/g, 'GOLDEN HILL');
  formatted = formatted.replace(/GOLDEN$/g, 'GOLDEN HILL');
  formatted = formatted.replace(/HIILLCREST|HILCREST|HILL CREST|HILLCREAST/g, 'HILLCREST');
  formatted = formatted.replace(/KRNY|KEARRY|KEARY|KERANY|KERNNY|KERNY|KERRNY/g, 'KEARNY');
  formatted = formatted.replace(/KEARNY$|KEARN\s/g, 'KEARNY MESA');
  formatted = formatted.replace(/LAJOLLA|L A JOLLA|L AJOLLA|LA JOLLLA|LA JOLLIA|LA JOLLLA|LA LOLLA|LJ$|LA JALLA/g, 'LA JOLLA');
  formatted = formatted.replace(/LV|L V/g, 'LINDA VISTA');
  formatted = formatted.replace(/LINDIA|LINDO|LINDA$/g, 'LINDA VISTA');
  formatted = formatted.replace(/LITLE|LITLLE|LITLTE|LITTILE|LITTLEY|LITTLY|LITTTE|LITTTLE/g, 'LITTLE');
  formatted = formatted.replace(/LOAGN|LOGON|LODGAN/g, 'LOGAN');
  formatted = formatted.replace(/LOGAN$/g, 'LOGAN HEIGHTS');
  formatted = formatted.replace(/MARNIA/g, 'MARINA');
  formatted = formatted.replace(/MISSIONHILLS/g, 'MISSION HILLS');
  formatted = formatted.replace(/MID-CITY|MID CITY/g, 'MIDCITY');
  formatted = formatted.replace(/MID-TOWN|MID TOWN|MIDTOWEN/g, 'MIDTOWN');
  formatted = formatted.replace(/MVW|M V W|MV W/g, 'MISSION VALLEY WEST');
  formatted = formatted.replace(/MVE|M V E|MV E/g, 'MISSION VALLEY EAST');
  formatted = formatted.replace(/NORMAL$/g, 'NORMAL HEIGHTS');
  formatted = formatted.replace(/OCEAN BECH/g, 'OCEAN BEACH');
  formatted = formatted.replace(/PARK(EAST|WEST)$/g, 'PARK $1');
  formatted = formatted.replace(/PAR(EAST|WEST)$/g, 'PARK $1');
  formatted = formatted.replace(/PETCON|PETOC/g, 'PETCO');
  formatted = formatted.replace(/POINTLOMA|PONIT LOMA|PONT LOMA|PT LOMA/g, 'POINT LOMA');
  formatted = formatted.replace(/ROB FIELD|ROB FIELDS|ROBB FIELDS/g, 'ROBB FIELD');
  formatted = formatted.replace(/SERRA$|SERRIA$|SIERRA$/g, 'SERRA MESA');
  formatted = formatted.replace(/SOUTHEASTEN|SOUTHEASTERN/g, 'SOUTHEAST');
  formatted = formatted.replace(/TIERRA$|TIERRASANTA/g, 'TIERRA SANTA');
  formatted = formatted.replace(/UNIV CITY|UTC/g, 'UNIVERSITY CITY');
  formatted = formatted.replace(/UNIV HEIGHTS|UTC/g, 'UNIVERSITY HEIGHTS');
  formatted = formatted.replace(/WIND AND SEA/g, 'WINDANSEA');

  // Replace permit assignments.
  formatted = formatted.replace(/(\w)\s{0,}PERMIT/g, '$1 PERMIT');

  if (trailing) {
    // Remove trailing spaces.
    formatted = formatted.trim();

    // Remove trailing characters that are not letters or numbers.
    formatted = formatted.replace(/[^\w]$/g, '');

    // Fix abbreviations at the end of the string.
    // These replacements are saved until the string is ready to be sent to the database because
    // the abbreviations here could have been parts of another word and shouldn't be automatically
    // replaced onChange.
    formatted = formatted.replace(/ N$/g, ' NORTH');
    formatted = formatted.replace(/ S$/g, ' SOUTH');
    formatted = formatted.replace(/ E$/g, ' EAST');
    formatted = formatted.replace(/ W$/g, ' WEST');
    formatted = formatted.replace(/ HEIGHT$/g, ' HEIGHTS');
    formatted = formatted.replace(/MISSION HILL$/g, 'MISSION HILLS');
    formatted = formatted.replace(/MV$/g, 'MISSION VALLEY');

    // Replace multiple forward slashes with single forward slashes. We do this again in case the code above introduced a second slash.
    formatted = formatted.replace(/\/\/+/g, '/');

    // Remove any more trailing spaces that were revealed after removing the previous characters.
    formatted = formatted.trim();
  }

  return formatted;
};

export default formatInput;
