import { useState } from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import api from '../../constants/api';
import Container from '../table/Container';
import Form from './Form';

/**
 * Other properties include:
 * minWidth: 170,
 * align: 'right',
 * format: (value) => value.toLocaleString('en-US')
 */
const columns = [
  { id: 'squadOrder', label: 'Squad order' },
  { id: 'callSignGroup', label: 'Call sign group' },
  { id: 'stationDescription', label: 'Station' },
  { id: 'daysOff', label: 'Days off' },
  { id: 'workingHours', label: 'Working hours' },
  { id: 'active', label: 'Active' },
];

function createRows(data) {
  const rows = [];

  data.forEach((row) => {
    rows.push({
      squadOrder: row.squadOrder,
      callSignGroup: row.callSignGroup,
      stationDescription: `${row.stationDescription[0].toUpperCase()}${row.stationDescription.slice(1)}`,
      daysOff: row.daysOff,
      workingHours: row.workingHours,
      active: row.active === 1 ? <CheckIcon title="active" /> : <CloseIcon title="inactive" />,
      squadID: row.squadID,
      stationID: row.stationID,
    });
  });

  return rows;
}

export default function Squads() {
  const [open, setOpen] = useState(false);
  const apiAddress = {
    fetchRecords: `${api.address}/squad/read.php?action=squadTable`,
    fetchRecordAndSelectOptions: `${api.address}/squad/read.php?action=editSquad&squadID=`,
    fetchForm: `${api.address}/squad/read.php?action=getForm`,
  };
  const primaryKey = 'squadID';

  return (
    <>
      <Collapse in={open}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          The administration squad is a special squad that cannot be edited or removed.
        </Alert>
      </Collapse>
      <Container
        apiAddress={apiAddress}
        columns={columns}
        createRows={createRows}
        primaryKey={primaryKey}
        Form={Form}
        customEdit={(recordID) => {
          if (recordID === 1) {
            // The user has clicked the "administration" squad.
            setOpen(true);

            return false;
          }

          return true
        }}
      />
    </>
  );
}
