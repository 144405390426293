import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useSiteStore from '../../hooks/useSiteStore';

const styles = {
  active: {
    backgroundColor: 'hsl(35 28% 86%)',
  },
  link: {
    marginLeft: 0,
    textDecoration: 'none',
  },
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  flexShrink: 1,
  cursor: 'pointer',
  '&:hover': {
    color: 'black',
  },
  marginLeft: '0 !important',
  display: 'flex',
  alignItems: 'center',
}));

function DataMenu({ anchorEl, setAnchorEl }) {
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const closeThenNavigate = (target) => {
    setAnchorEl(null);
    navigate(target);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={() => closeThenNavigate('data/statuses')}>Statuses</MenuItem>
      <MenuItem onClick={() => closeThenNavigate('data/employees')}>Employees</MenuItem>
    </Menu>
  );
}

export default function NavigationButton({ children, onClick, target }) {
  const screenID = useSiteStore((state) => state.screenID);
  const [anchorEl, setAnchorEl] = useState(null);

  function screenMatches(target) {
    if (screenID === target) {
      return true;
    }

    if (target === 'data' && screenID.includes('data')) {
      return true;
    }

    return false;
  }

  if (target === 'data') {
    return (
      <>
        <StyledPaper
          elevation={0}
          square
          sx={screenMatches(target) ? styles.active : {}}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {children}
        </StyledPaper>
        <DataMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </>
    );
  } else if (typeof target === 'undefined') {
    return (
      <StyledPaper
        elevation={0}
        square
        sx={screenMatches(target) ? styles.active : {}}
        onClick={onClick}
      >
        {children}
      </StyledPaper>
    );
  }

  return (
    <Link to={`/${target}`} style={styles.link}>
      <StyledPaper
        elevation={0}
        square
        sx={screenMatches(target) ? styles.active : {}}
      >
        {children}
      </StyledPaper>
    </Link>
  );
}
