import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Card  from '@mui/material/Card';
import CardContent  from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useStatusDataStore } from '../../../hooks/useDataStore';
import { Calendar } from '../charts';
import styles from '../styles';

const fromDate = DateTime.now().set({ month: 1, day: 1}).toISODate();
const toDate = DateTime.now().set({ month: 12 }).endOf('month').toISODate();

export default function Chart5() {
  const chartData = useStatusDataStore((state) => state.chartData5);
  const abbreviationConstants = useStatusDataStore((state) => state.constants.statusAbbreviation);
  const employeeConstants = useStatusDataStore((state) => state.constants.employee);
  const setChartData = useStatusDataStore((state) => state.setChartData);
  const setFilters = useStatusDataStore((state) => state.setFilters);

  const [statusAbbreviationID, setStatusAbbreviationID] = useState(0);
  const [employeeID, setEmployeeID] = useState(0);

  const statusAbbreviations = typeof abbreviationConstants !== 'undefined'
    ? Object.values(abbreviationConstants).sort((a, b) => `${a.statusAbbreviation}`.localeCompare(`${b.statusAbbreviation}`))
    : [];

  const employees = typeof employeeConstants !== 'undefined'
    ? Object.values(employeeConstants).sort((a, b) => a.lastname.localeCompare(b.lastname))
    : [];

  const handleChange = ({ target }, filterName) => {
    if (filterName === 'status-abbreviation-id') {
      setStatusAbbreviationID(target.value);
    } else if (filterName === 'employee-id') {
      setEmployeeID(target.value);
    }

    setChartData(5, []);
    setFilters(5, filterName, target.value);
  };

  useEffect(() => {
    setFilters(5, 'status-abbreviation-id', statusAbbreviationID);
  }, []);

  return (
    <Container maxWidth={false} sx={styles.entry}>
      <Stack direction="row" sx={{ alignSelf: 'start' }}>
        <Typography variant="h3" id="split-day-calendar">
          Employee split-day status calendar
        </Typography>
        <Link href="#split-day-calendar">
          <IconButton aria-label="link to this chart" color="secondary">
            <LinkIcon fontSize="large" />
          </IconButton>
        </Link>
      </Stack>
      <Container maxWidth={false} sx={styles.chart}>
        {chartData.length > 0
          ? <Calendar
              data={chartData}
              from={fromDate}
              to={toDate}
              tooltip={(n) => {
                return (
                  <Card variant="outlined">
                    <CardContent>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {DateTime.fromISO(n.day).weekdayLong}
                      </Typography>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {n.day}
                      </Typography>
                      <Typography variant="h5" component="div" sx={{ color: n.color }}>
                        {n.data.statusDescription}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              }}
            />
          : <Skeleton variant="rectangular" width={600} height={600} />}
      </Container>
      <Paper elevation={4} sx={styles.information}>
        <FormControl sx={{ marginLeft: '1rem' }}>
          <InputLabel id="5-employee-id-label">employee</InputLabel>
          <Select
            labelId="5-employee-id-label"
            id="5-employee-id"
            value={employeeID}
            label="Employee"
            onChange={(event) => handleChange(event, 'employee-id')}
            sx={styles.input}
          >
            <MenuItem value={0} key={0}><span style={{ fontStyle: 'italic' }}>Choose an employee</span></MenuItem>
            {employees.map((employee) => <MenuItem value={employee.employeeID} key={employee.employeeID}>{`${employee.lastname}, ${employee.firstname}`}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '1rem' }}>
          <InputLabel id="5-status-abbreviation-id-label">status</InputLabel>
          <Select
            labelId="5-status-abbreviation-id-label"
            id="5-status-abbreviation-id"
            value={statusAbbreviationID}
            label="status"
            onChange={(event) => handleChange(event, 'status-abbreviation-id')}
            sx={styles.input}
          >
            <MenuItem value={0}><span style={{ fontStyle: 'italic' }}>All</span></MenuItem>
            {statusAbbreviations.map((abbreviation) => <MenuItem value={abbreviation.statusAbbreviationID} key={abbreviation.statusAbbreviationID}>{abbreviation.statusAbbreviation}</MenuItem>)}
          </Select>
        </FormControl>
      </Paper>
      <Paper elevation={4} sx={styles.information}>
        This chart requires you to choose a single employee from among the filters.
        After selecting an employee the calendar will show each day where a split-day status was given to that employee.
        A split-day status is any status that was not 10 hours.
        For example, a status of TO3 BOS is a split day status because the employee had 3 hours of time off and worked the remaining 7 hours.
      </Paper>
    </Container>
  );
}
