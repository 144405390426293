import create from 'zustand';
import api from '../constants/api';

const parsedUrl = new URL(window.location.href);
const apiKey = parsedUrl.searchParams.get('apikey');
const address = {
  token: `${api.address}/authenticate/authenticateToken.php`,
  apiKey: `${api.address}/authenticate/authenticateApiKey.php?apikey=${apiKey}`,
  login: `${api.address}/authenticate/login.php`,
};

const useAuthStore = create((set, get) => ({
  userID: '',
  setUserID: (userID) => set({ userID }),
  token: '',
  role: 'VIEWER',
  getAuthPackage: (stringify) => {
    const { userID, token, role } = get();

    return stringify ? JSON.stringify({ userID, token, role }) : { userID, token, role };
  },
  authorized: false,
  // Shows the full window loading animation.
  checking: false,
  // Shows the login form loading animation.
  loading: false,
  setLoading: (loading) => set({ loading }),
  error: false,
  usingApiKey: false,
  delete: () => {
    set({
      userID: '',
      token: '',
      role: 'VIEWER',
      authorized: false,
    });

    localStorage.clear();
  },
  readLocally: async () => {
    const localAuthEncoded = localStorage.getItem('authorization');

    if (localAuthEncoded) {
      const { userID, token, role } = JSON.parse(localAuthEncoded);

      set({
        userID,
        token,
        role,
        checking: true,
      });

      get().checkToken();
    }
  },
  storeLocally: () => {
    const { userID, token, role } = get();

    const newStorageValues = {
      userID,
      token,
      role,
    };

    localStorage.setItem('authorization', JSON.stringify(newStorageValues));
  },
  checkAPIKey: async () => {
    set({ loading: true, usingApiKey: true });

    console.debug('Checking the API key.');

    // This endpoint returns true or false based on validity.
    await fetch(address.apiKey)
      .then((data) => data.json())
      .then((data) => {
        if (!data) {
          // The api key is not valid.
          get().delete();
        } else {
          set({
            userID: 0,
            token: apiKey,
            // Set this API key user to the VIEWER role.
            role: 'VIEWER',
            authorized: true,
          });
        }
      });

    set({ loading: false });
  },
  checkToken: () => {
    set({ checking: true });

    fetch(address.token, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: get().token,
        userID: get().userID,
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (!data.error === false) {
          console.error(data.errorMessage);

          get().delete();
        } else if (!data.authorized) {
          // The token is not valid.
          console.error('The token is invalid. Clearing local storage.');

          get().delete();
        } else {
          set({ authorized: true });
        }

        set({ checking: false });
      });
  },
  logout: async () => {
    console.debug('Logging out the user.');

    if (get().userID !== 0) {
      await fetch(`${api.address}/authenticate/logout.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(get().getAuthPackage()),
      });
    }

    get().delete();
  },
  login: async (credentials) => {
    set({ error: false, loading: true });

    await fetch(address.login, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          console.error(data.errorMessage);

          set({ error: true, loading: false });
        } else {
          set({
            userID: data.userID,
            token: data.token,
            role: data.role,
            authorized: true,
            loading: false,
          });

          get().storeLocally();
        }
      });
  },
}));

if (apiKey !== null) {
  useAuthStore.getState().checkAPIKey();
} else {
  useAuthStore.getState().readLocally();
}

export default useAuthStore;
