import { useRef, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import RedoIcon from '@mui/icons-material/Redo';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import api from '../../constants/api';
import formatInput from '../../constants/autocorrect';
import calculateCenter from './calculateCenter';
import useAuthStore from '../../hooks/useAuthStore';

const styles = {
  list: {
    width: '100%',
    bgcolor: 'background.paper',
  },
  formContainer: {
    marginLeft: '1em',
    width: '88%',
  },
  paper: {
    backgroundColor: 'rgb(255, 253, 250)',
    marginTop: '1rem',
    padding: '.5rem 1rem',
  },
  divider: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  paddingMiddle: {
    flexGrow: 1,
    display: 'flex',
  },
};

function Form(props) {
  const authPackage = useAuthStore((state) => state.getAuthPackage);

  const {
    assignmentID,
    assignmentActive,
    assignmentCoordinates,
    assignmentDescription,
    clearForm,
    editMode,
    newAssignment,
    removeCoordinates,
    setAssignmentActive,
    setAssignmentDescription,
    setLoading,
    errorMessage,
    setErrorMessage,
  } = props;

  if (!editMode || (!newAssignment && assignmentID === null)) {
    return null;
  }

  const submitForm = () => {
    // Show the loading animation to prevent further values from changing.
    setLoading(true);

    const apiAddress = newAssignment ? `${api.address}/assignment/create.php` : `${api.address}/assignment/update.php?assignmentID=${assignmentID}`;
    const values = {
      active: assignmentActive,
      assignmentDescription : formatInput(assignmentDescription),
      coordinates: assignmentCoordinates,
      token: authPackage(),
    };

    if (!newAssignment) {
      // Add the assignment ID if updating a record.
      values.assignmentID = assignmentID;
    }

    fetch(apiAddress, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          console.error(data.errorMessage);
        } else {
          clearForm(newAssignment ? data.assignmentID : assignmentID);
        }

        setLoading(false);
      });
  };

  const handleDeletion = () => {
    console.debug(`The user wants to delete assignment ${assignmentID}`);

    setLoading(true);

    fetch(`${api.address}/assignment/delete.php?assignmentID=${assignmentID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          clearForm(assignmentID, true);
        }

        setLoading(false);
      });
  };

  return (
    <div style={styles.formContainer}>
      {errorMessage !== '' ? <Alert severity="warning" sx={{ marginBottom: '1em' }}>{errorMessage}</Alert> : null}
      <FormControl fullWidth>
        <TextField
          id="input-assignment-description"
          label="Description"
          variant="outlined"
          value={assignmentDescription}
          onChange={({ target }) => {
            setAssignmentDescription(formatInput(target.value.toUpperCase()));
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: '1rem' }}>
        <InputLabel id="select-active-label">Active</InputLabel>
        <Select
          labelId="select-active-label"
          id="select-active"
          value={assignmentActive}
          label="Active"
          onChange={({ target }) => setAssignmentActive(target.value)}
          required
        >
          <MenuItem value={1}>Yes</MenuItem>
          <MenuItem value={0}>No</MenuItem>
        </Select>
      </FormControl>
      <Paper sx={{ ...styles.paper, display: 'flex', alignItems: 'center' }}>
        <div style={{ flexGrow: 1 }}>{assignmentCoordinates?.length ?? 0} coordinates</div>
        <Button sx={{ flexShrink: 1 }} title="Delete the current shape" onClick={removeCoordinates}><RedoIcon /></Button>
      </Paper>
      <Paper sx={styles.paper}>
        <ul style={{ paddingInlineStart: '10px' }}>
          <li>Click on the map to create a new shape.</li>
          <li>Press the ESCAPE key to finalize the shape.</li>
          <li>The current shape will be overwritten if you click the update button.</li>
        </ul>
      </Paper>
      <div style={{ display: 'flex', marginTop: '1rem' }}>
        <Button variant="outlined" onClick={submitForm}>
          {newAssignment ? 'Create' : 'Update'}
        </Button>
        <div style={styles.paddingMiddle} />
        {!newAssignment ? <Button variant="outlined" onClick={handleDeletion} color="error">Delete</Button> : null}
      </div>
      <Divider sx={styles.divider} />
    </div>
  );
};

export default function LeftPane(props) {
  const {
    assignments,
    setAssignments,
    show,
    clearForm,
    filterID,
    changeFilter,
    editMode,
    setFlyCoordinates,
    setLoading,
    assignmentID,
    setAssignmentID,
    assignmentActive,
    setAssignmentActive,
    assignmentCoordinates,
    setAssignmentCoordinates,
    assignmentDescription,
    setAssignmentDescription,
    newAssignment,
    setNewAssignment,
  } = props;

  const containerReference = useRef(null);

  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = (aID) => {
    if (editMode) {
      const newAssignments = { ...assignments };

      // This is not a new assignment.
      setNewAssignment(false);

      // Set the values within the form.
      setAssignmentDescription(newAssignments[aID].assignmentDescription);
      setAssignmentID(newAssignments[aID].assignmentID);
      let coord = newAssignments[aID].coordinates;

      if (Object.prototype.hasOwnProperty.call(newAssignments[aID], 'originalCoordinates')) {
        coord = newAssignments[aID].originalCoordinates;

        // Replace the coordinates in the object so that it shows again in the map.
        newAssignments[aID].coordinates = newAssignments[aID].originalCoordinates;
      }

      setAssignmentCoordinates(coord);
      setAssignmentActive(newAssignments[aID].active ? 1 : 0);

      console.debug(`The user wants to edit assignment ${newAssignments[aID].assignmentID}.`);

      newAssignments[aID].center = [];

      if (newAssignments[aID].coordinates.length > 0) {
        // Save this original coordinate array so we can reload the shape if the user clicks the entry in the list again before updating the form.
        newAssignments[aID].originalCoordinates = newAssignments[aID].coordinates;
        newAssignments[aID].center = calculateCenter(newAssignments[aID].coordinates);

        setAssignments(newAssignments);
      }

      containerReference.current.scrollIntoView({ behavior: 'smooth' });

      setFlyCoordinates(newAssignments[aID].center);
    } else {
      const newAssignments = { ...assignments };

      newAssignments[aID].checked = !newAssignments[aID].checked;

      console.debug(`Toggling the visibility of ID ${aID}.`);

      setAssignments(newAssignments);
    }
  };

  const toggleAllCheckboxes = () => {
    const newAssignments = { ...assignments };

    Object.values(newAssignments).forEach((assignment) => assignment.checked = !assignment.checked);

    setAssignments(newAssignments);
  };

  const showingCount = Object.values(assignments).filter((assignment) => show(assignment))?.length;

  const Icon = ({ checked, labelID }) => {
    if (editMode) {
      return null;
    }

    return (
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelID }}
        />
      </ListItemIcon>
    );
  };

  const removeCoordinates = () => {
    setAssignmentCoordinates([]);

    const newArray = { ...assignments };
    newArray[assignmentID].coordinates = [];
    newArray[assignmentID].center = [];

    setAssignments(newArray);
  };

  const setNewForm = () => {
    clearForm();
    setNewAssignment(true);
    setErrorMessage('');
  };

  const NewRecordIcon = ({ setNewForm }) => {
    if (!editMode) {
      return null;
    }

    return (
      <ListItem key="create-new-record" disablePadding>
        <ListItemButton role={undefined} onClick={setNewForm} dense>
          <AddCircleOutlineIcon />
        </ListItemButton>
      </ListItem>
    );
  };

  const sortedAssignments = Object.values(assignments).sort((a, b) => a.assignmentDescription.localeCompare(b.assignmentDescription));

  return (
    <List dense={true} sx={styles.list} ref={containerReference}>
      <FormControl sx={styles.formContainer}>
        <InputLabel id="show-select-label">Show</InputLabel>
        <Select
          labelId="show-select-label"
          id="show-select"
          value={filterID}
          label="Show"
          onChange={changeFilter}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>Missing shape</MenuItem>
          <MenuItem value={2}>Todays' schedule</MenuItem>
          <MenuItem value={3}>Inactive</MenuItem>
        </Select>
        <Paper sx={styles.paper}>Showing {showingCount} assignment{showingCount > 0 ? 's' : ''}</Paper>
      </FormControl>
      <Button variant="outlined" onClick={toggleAllCheckboxes} sx={{ margin: '1rem 0 0 1rem' }}>Toggle all</Button>
      <Divider sx={styles.divider} />
      <Form
        assignmentID={assignmentID}
        assignmentActive={assignmentActive}
        assignmentCoordinates={assignmentCoordinates}
        assignmentDescription={assignmentDescription}
        clearForm={clearForm}
        editMode={editMode}
        newAssignment={newAssignment}
        removeCoordinates={removeCoordinates}
        setAssignmentActive={setAssignmentActive}
        setAssignmentDescription={setAssignmentDescription}
        setLoading={setLoading}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      <NewRecordIcon setNewForm={setNewForm} />
      {sortedAssignments.map((assignment) => {
        if (!show(assignment)) {
          return null;
        }

        const labelID = `checkbox-list-label-${assignment.assignmentID}`;

        return (
          <ListItem key={assignment.assignmentID} disablePadding>
            <ListItemButton role={undefined} onClick={() => handleClick(assignment.assignmentID) } dense>
              <Icon checked={assignment.checked} labelID={labelID} />
              <ListItemText id={labelID} primary={assignment.assignmentDescription} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
