import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import PermissionsGate from '../PermissionsGate';
import { SCOPES } from '../../constants/permission-maps.js';
import NavigationButton from './NavigationButton';
import useSiteStore from '../../hooks/useSiteStore';

export default function NavigationLinks({ closeDrawer, handleDatePick }) {
  const compactNavigation = useSiteStore((state) => state.compactNavigation);

  const navigate = useNavigate();

  const changeToToday = () => {
    handleDatePick(true);
    navigate('schedule');
  };

  const mobileClick = useCallback((screen) => {
    navigate(screen);
    closeDrawer();
  }, [closeDrawer, navigate]);

  if (compactNavigation) {
    return (
      <>
        <PermissionsGate scopes={[SCOPES.canEdit]}>
          <ListItem disablePadding onClick={() => mobileClick('assignments')}>
            <ListItemButton>
              <ListItemIcon />
              <ListItemText primary="Assignments" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => mobileClick('employees')}>
            <ListItemButton>
              <ListItemIcon />
              <ListItemText primary="Employees" />
            </ListItemButton>
          </ListItem>
        </PermissionsGate>
        <PermissionsGate scopes={[SCOPES.canCreate]}>
          <ListItem disablePadding onClick={() => mobileClick('squads')}>
            <ListItemButton>
              <ListItemIcon />
              <ListItemText primary="Squads" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => mobileClick('ranks')}>
            <ListItemButton>
              <ListItemIcon />
              <ListItemText primary="Ranks" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => mobileClick('users')}>
            <ListItemButton>
              <ListItemIcon />
              <ListItemText primary="Website users" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => mobileClick('apikeys')}>
            <ListItemButton>
              <ListItemIcon />
              <ListItemText primary="API keys" />
            </ListItemButton>
          </ListItem>
        </PermissionsGate>
      </>
    );
  }

  return (
    <Stack direction="row" justifyContent="center" spacing={4}>
      <NavigationButton onClick={changeToToday}>Today</NavigationButton>
      <PermissionsGate scopes={[SCOPES.canEdit]}>
        <NavigationButton target="assignments">Assignments</NavigationButton>
        <NavigationButton target="employees">Employees</NavigationButton>
      </PermissionsGate>
      <PermissionsGate scopes={[SCOPES.canCreate]}>
        <NavigationButton target="squads">Squads</NavigationButton>
        <NavigationButton target="ranks">Ranks</NavigationButton>
        <NavigationButton target="users">Website users</NavigationButton>
        <NavigationButton target="apikeys">API keys</NavigationButton>
        <NavigationButton target="data">Data</NavigationButton>
      </PermissionsGate>
    </Stack>
  );
}
