import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LeftPane from './LeftPane';
import MapContainer from './OpenStreetMap';
import api from '../../constants/api';
import calculateCenter from './calculateCenter';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 55px)',
    width: '100%',
  },
  pane: {
    width: '300px',
    backgroundColor: 'transparent',
    borderRight: '2px solid rgba(0,0,0,0.2)',
    overflowY: 'scroll',
  },
  mapcontainer: {
    flexGrow: '1',
  },
  loading: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
};

export default function Layout() {
  const [assignments, setAssignments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [newAssignment, setNewAssignment] = useState(false);
  const [filterID, setFilterID] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [flyToCoordinates, setFlyCoordinates] = useState([]);

  const [assignmentID, setAssignmentID] = useState(null);
  const [assignmentActive, setAssignmentActive] = useState(1);
  const [assignmentCoordinates, setAssignmentCoordinates] = useState([]);
  const [assignmentDescription, setAssignmentDescription] = useState('');

  const clearForm = (aID = false, deleteRecord = false) => {
    if (aID) {
      const newAssignments = { ...assignments };

      if (newAssignment) {
        // Insert this new record.
        newAssignments[aID] = {
          inSchedule: false,
          checked: true,
          coordinates: assignmentCoordinates,
          assignmentID: aID,
          assignmentDescription,
          active: assignmentActive === 1,
        };

        console.log(newAssignments[aID]);
      } else if (deleteRecord) {
        console.debug(`Deleting assignment ${aID} from the assignment array.`);

        delete newAssignments[aID];
      } else {
        // Update the assignment object with the values the user just saved in the form.
        console.debug(`Updating assignment ${aID} in the assignment array.`);

        newAssignments[aID].active = assignmentActive;
        newAssignments[aID].coordinates = assignmentCoordinates;
        newAssignments[aID].center = calculateCenter(assignmentCoordinates);
        newAssignments[aID].assignmentDescription = assignmentDescription;

        delete newAssignments[aID].originalCoordinates;
      }

      setAssignments(newAssignments);
    }

    setAssignmentID(null);
    setAssignmentActive(1);
    setAssignmentCoordinates([]);
    setAssignmentDescription('');

    setNewAssignment(false);
  };

  useEffect(() => {
    setLoading(true);

    fetch(`${api.address}/assignment/read.php?action=map`)
      .then(data => data.json())
      .then((data) => {
        const buildAssignments = {};

        data.body.forEach((assignment) => {
          buildAssignments[assignment.assignmentID] = {
            inSchedule: assignment.inSchedule !== 0,
            checked: true,
            coordinates: assignment.coordinates,
            center: calculateCenter(assignment.coordinates),
            assignmentID: assignment.assignmentID,
            assignmentDescription: `${assignment.assignmentDescription}`,
            active: assignment.active,
          }
        });

        setAssignments(buildAssignments);

        setLoading(false);
      });
  }, []);

  const show = (assignment) => {
    let showThis = assignment.active;

    if (filterID === 1 && assignment.coordinates.length > 0) {
      // Do not show entries that already have polygon coordinates.
      showThis = false;
    } else if (filterID === 2 && !assignment.inSchedule) {
      // Do not show entries that are not in todays' schedule.
      showThis = false;
    } else if (filterID === 3) {
      // Show inactive entries only if the third filter was selected.
      showThis = assignment.active === false || assignment.active === 0;
    }

    return showThis;
  };

  const changeFilter = ({ target }) => {
    if (target.value !== filterID) {
      clearForm();

      setFilterID(target.value);
    }
  };

  return (
    <div className="mapPageContainer" style={styles.container}>
      <div style={styles.pane}>
        <Backdrop open={isLoading} sx={styles.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <LeftPane
          assignments={assignments}
          setAssignments={setAssignments}
          editMode={editMode}
          show={show}
          clearForm={clearForm}

          filterID={filterID}
          changeFilter={changeFilter}

          setFlyCoordinates={setFlyCoordinates}
          setLoading={setLoading}

          assignmentID={assignmentID}
          setAssignmentID={setAssignmentID}
          assignmentActive={assignmentActive}
          setAssignmentActive={setAssignmentActive}
          assignmentCoordinates={assignmentCoordinates}
          setAssignmentCoordinates={setAssignmentCoordinates}
          assignmentDescription={assignmentDescription}
          setAssignmentDescription={setAssignmentDescription}

          newAssignment={newAssignment}
          setNewAssignment={setNewAssignment}
        />
      </div>
      <div className="mapContainer" style={styles.mapcontainer}>
        <MapContainer
          assignments={assignments}
          setAssignments={setAssignments}
          editMode={editMode}
          show={show}
          clearForm={clearForm}

          setEditMode={setEditMode}
          flyToCoordinates={flyToCoordinates}

          assignmentID={assignmentID}
          assignmentCoordinates={assignmentCoordinates}
          setAssignmentCoordinates={setAssignmentCoordinates}

          newAssignment={newAssignment}
        />
      </div>
    </div>
  );
}
