import { useState } from 'react';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useStatusDataStore } from '../../../hooks/useDataStore';
import { Pie } from '../charts';
import styles from '../styles';

export default function Chart1() {
  const chartData = useStatusDataStore((state) => state.chartData1);
  const employeeConstants = useStatusDataStore((state) => state.constants.employee);
  const setChartData = useStatusDataStore((state) => state.setChartData);
  const setFilters = useStatusDataStore((state) => state.setFilters);

  const [timeRange, setTimeRange] = useState('two-years');
  const [employeeID, setEmployeeID] = useState(0);

  const employees = typeof employeeConstants !== 'undefined'
    ? Object.values(employeeConstants).sort((a, b) => a.lastname.localeCompare(b.lastname))
    : [];

  const handleChange = ({ target }, filterName) => {
    if (filterName === 'time-range') {
      setTimeRange(target.value);
    } else if (filterName === 'employee-id') {
      setEmployeeID(target.value);
    }

    setChartData(1, []);
    setFilters(1, filterName, target.value);
  };

  return (
    <Container maxWidth={false} sx={styles.entry}>
      <Stack direction="row" sx={{ alignSelf: 'start' }}>
        <Typography variant="h3" id="most-common">
          The most commonly used statuses
        </Typography>
        <Link href="#most-common">
          <IconButton aria-label="link to this chart" color="secondary">
            <LinkIcon fontSize="large" />
          </IconButton>
        </Link>
      </Stack>
      <Container maxWidth={false} sx={styles.chart}>
        {chartData.length > 0 ? <Pie data={chartData} /> : <Skeleton variant="circular" width={600} height={600} />}
      </Container>
      <Paper elevation={4} sx={styles.information}>
        <FormControl>
          <InputLabel id="1-time-range-label">time range</InputLabel>
          <Select
            labelId="1-time-range-label"
            id="1-time-range"
            value={timeRange}
            label="Time range"
            onChange={(event) => handleChange(event, 'time-range')}
            sx={styles.input}
          >
            <MenuItem value="two-years">Previous two years</MenuItem>
            <MenuItem value="one-year">Previous year</MenuItem>
            <MenuItem value="six-months">Previous 6 months</MenuItem>
            <MenuItem value="three-months">Previous 3 months</MenuItem>
            <MenuItem value="previous-month">Previous month</MenuItem>
            <MenuItem value="year-start">Since January 1st</MenuItem>
            <MenuItem value="this-month">This month</MenuItem>
            <MenuItem value="today">Today</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '1rem' }}>
          <InputLabel id="1-employee-id-label">employee</InputLabel>
          <Select
            labelId="1-employee-id-label"
            id="1-employee-id"
            value={employeeID}
            label="Employee"
            onChange={(event) => handleChange(event, 'employee-id')}
            sx={styles.input}
          >
            <MenuItem value={0}><span style={{ fontStyle: 'italic' }}>All</span></MenuItem>
            {employees.map((employee) => <MenuItem value={employee.employeeID} key={employee.employeeID}>{`${employee.lastname}, ${employee.firstname}`}</MenuItem>)}
          </Select>
        </FormControl>
      </Paper>
      <Paper elevation={4} sx={styles.information}>
        These are the status types that have been used within a timeframe.
        This is not the number of hours, but the number of times the status has been given to an employee.
        You can filter by the timeframe and by single employees.
      </Paper>
    </Container>
  );
}
