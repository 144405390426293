import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../constants/api';
import Container from '../table/Container';
import Form from './Form';
import useAuthStore from '../../hooks/useAuthStore';

/**
 * Other properties include:
 * minWidth: 170,
 * align: 'right',
 * format: (value) => value.toLocaleString('en-US')
 */
const columns = [
  { id: 'lastname', label: 'Last name' },
  { id: 'firstname', label: 'First name' },
  { id: 'rankDescriptionShort', label: 'Rank' },
  { id: 'unit', label: 'Unit' },
  { id: 'squadOrder', label: 'Squad' },
  // { id: 'pdid', label: 'PDID' },
  { id: 'vehicle', label: 'Vehicle' },
  { id: 'radio', label: 'Radio' },
  {
    id: 'daysActive',
    label: 'Days Active',
    format: (value) => value.toLocaleString('en-US'),
  },
  { id: 'active', label: 'Active' },
];

function createRows(data) {
  const rows = [];

  data.forEach((row) => {
    rows.push({
      firstname: row.firstname,
      lastname: row.lastname,
      rankDescriptionShort: row.rankDescriptionShort.toUpperCase(),
      unit: row.unit,
      squadOrder: row.squadOrder,
      pdid: row.pdid,
      vehicle: row.vehicle,
      radio: row.radio,
      active: row.active === 1 ? <CheckIcon title="active" /> : <CloseIcon title="inactive" />,
      employeeID: row.employeeID,
      badge: row.badge,
      cityStartDate: row.cityStartDate,
      parkingStartDate: row.parkingStartDate,
      parkingEndDate: row.parkingEndDate,
      daysActive: row.daysActive,
    });
  });

  return rows;
}

export default function Squads() {
  const authPackage = useAuthStore((state) => state.getAuthPackage);
  const encodedToken = window.btoa(authPackage(true));
  const apiAddress = {
    fetchRecords: `${api.address}/employee/read.php?action=employeeTable&token=${encodedToken}`,
    fetchRecordAndSelectOptions: `${api.address}/employee/read.php?action=editEmployee&token=${encodedToken}&employeeID=`,
    fetchForm: `${api.address}/employee/read.php?action=getForm&token=${encodedToken}`,
  };
  const primaryKey = 'employeeID';

  return (
    <Container
      apiAddress={apiAddress}
      columns={columns}
      createRows={createRows}
      primaryKey={primaryKey}
      Form={Form}
    />
  );
}
