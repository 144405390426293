import { useState } from 'react';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useStatusDataStore } from '../../../hooks/useDataStore';
import { TreeMap } from '../charts';
import styles from '../styles';

export default function Chart3() {
  const chartData = useStatusDataStore((state) => state.chartData3);
  const setChartData = useStatusDataStore((state) => state.setChartData);
  const setFilters = useStatusDataStore((state) => state.setFilters);

  const [timeRange, setTimeRange] = useState('two-years');

  const handleChange = ({ target }, filterName) => {
    if (filterName === 'time-range') {
      setTimeRange(target.value);
    }

    setChartData(3, []);
    setFilters(3, filterName, target.value);
  };

  return (
    <Container maxWidth={false} sx={styles.entry}>
      <Stack direction="row" sx={{ alignSelf: 'start' }}>
        <Typography variant="h3" id="overtime-spent-per-squad">
          The amount of overtime hours spent by each squad
        </Typography>
        <Link href="#overtime-spent-per-squad">
          <IconButton aria-label="link to this chart" color="secondary">
            <LinkIcon fontSize="large" />
          </IconButton>
        </Link>
      </Stack>
      <Container maxWidth={false} sx={styles.chart}>
        {typeof chartData !== 'undefined' && Object.keys(chartData).length > 0
          ? <TreeMap data={chartData} property="hours" />
          : <Skeleton variant="rectangular" width={600} height={600} />}
      </Container>
      <Paper elevation={4} sx={styles.information}>
        <FormControl>
          <InputLabel id="3-time-range-label">time range</InputLabel>
          <Select
            labelId="3-time-range-label"
            id="3-time-range"
            value={timeRange}
            label="Time range"
            onChange={(event) => handleChange(event, 'time-range')}
            sx={styles.input}
          >
            <MenuItem value="two-years">Previous two years</MenuItem>
            <MenuItem value="one-year">Previous year</MenuItem>
            <MenuItem value="six-months">Previous 6 months</MenuItem>
            <MenuItem value="three-months">Previous 3 months</MenuItem>
            <MenuItem value="previous-month">Previous month</MenuItem>
            <MenuItem value="year-start">Since January 1st</MenuItem>
            <MenuItem value="this-month">This month</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Paper elevation={4} sx={styles.information}>
        This shows the amount of overtime assigned to employees and grouped by squad. You may filter the data by a time range.
      </Paper>
    </Container>
  );
}
