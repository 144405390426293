const markers = [
  {
    position: [32.715408559582386, -117.15105790183638],
    label: 'Headquarters',
  },
  {
    position: [32.70586989312147, -117.13996350147039],
    label: 'Central Division',
  },
  {
    position: [32.80944599831873, -117.12901884562417],
    label: 'Eastern Division',
  },
  {
    position: [32.74658643046968, -117.10107925860675],
    label: 'Midcity Division',
  },
  {
    position: [32.87695088239985, -117.21565820812549],
    label: 'Northern Division',
  },
  {
    position: [32.576090596185416, -117.07579662209706],
    label: 'Southern Division',
  },
  {
    position: [32.80930171251646, -117.12698420063909],
    label: 'Traffic Division',
  },
  {
    position: [32.764123686875976, -117.19450336997306],
    label: 'Western Division',
  },
];

const exports = { markers };

export default exports;
