import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware'
import { DateTime } from 'luxon';

const useScheduleStore = create(subscribeWithSelector((set, get) => ({
  assignments: [],
  setAssignments: (assignments) => set({ assignments }),
  assignmentMap: [],
  setAssignmentMap: (assignmentMap) => set({ assignmentMap }),
  updateAssignmentMap: (employeeID, newMap) => {
    const assignmentMap = { ...get().assignmentMap };

    assignmentMap[employeeID] = newMap;

    set({ assignmentMap });
  },
  creationString: '',
  setCreationString: (creationString) => set({ creationString }),
  error: false,
  setError: (error) => set({ error }),
  isOlderSchedule: false,
  noteVisibility: false,
  setNoteVisibility: (noteVisibility) => set({ noteVisibility }),
  // This will be a date time object. It is not formatted for display.
  scheduleDate: DateTime.now(),
  setScheduleDate: (scheduleDate) => set({ scheduleDate }),
  squads: [],
  setSquads: (squads) => set({ squads }),
  statuses: [],
  setStatuses: (statuses) => set({ statuses }),
  statusMap: [],
  setStatusMap: (statusMap) => set({ statusMap }),
  updateStatusMap: (employeeID, newMap) => {
    const statusMap = { ...get().statusMap };

    statusMap[employeeID] = newMap;

    set({ statusMap });
  },
})));

function calculateOlderSchedule(scheduleDate) {
  const end = DateTime.now();
  const start = scheduleDate;

  const difference = start.diff(end, 'days').toObject();

  return difference.days < -1;
}

useScheduleStore.subscribe((state) => state.scheduleDate, (scheduleDate, previousValue) => {
  useScheduleStore.setState({
    isOlderSchedule: calculateOlderSchedule(scheduleDate),
  });
});


export default useScheduleStore;
