import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/icons-material/Menu';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { styled } from '@mui/material/styles';
import NavigationLinks from './NavigationLinks';
import LogoutIcon from './LogoutIcon';
import NotificationIcon from './NotificationIcon';
import PrintIcon from './PrintIcon';
import RefreshIcon from './RefreshIcon';
import PermissionsGate from '../PermissionsGate';
import { SCOPES } from '../../constants/permission-maps.js';
import api from '../../constants/api';
import useAuthStore from '../../hooks/useAuthStore';
import useScheduleStore from '../../hooks/useScheduleStore';
import useSiteStore from '../../hooks/useSiteStore';

const styles = {
  container: {
    width: '100%',
    backgroundColor: 'hsl(36 100% 99%)',
    borderBottom: '1px solid hsl(35 28% 86%)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.6)',
    '&:hover': {
      color: 'hsl(28 27% 74%)',
    },
  },
  touchIcon: {
    fontSize: 30
  },
  calendarIcon: {
    marginRight: '.9em',
  },
  leftIconStack: {
    flexShrink: 1,
    marginLeft: '1rem',
  },
  rightIconStack: {
    flexShrink: 1,
    marginRight: '8px',
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  '& button' : {
    top: '25px',
    color: 'rgba(0, 0, 0, 0.6)',
  }
}));

const StyledTouchBox = styled(Box)(({ theme }) => ({
  '& button' : {
    left: '-11px',
    color: 'rgba(0, 0, 0, 0.6)',
  }
}));

const widthBreakPoint = 900;

export default function Navigation({ togglePrintMode }) {
  const authPackage = useAuthStore((state) => state.getAuthPackage);
  const compactNavigation = useSiteStore((state) => state.compactNavigation);
  const logout = useSiteStore((state) => state.logout);
  const printMode = useSiteStore((state) => state.printMode);
  const scheduleDate = useScheduleStore((state) => state.scheduleDate);
  const screenID = useSiteStore((state) => state.screenID);
  const setCompactNavigation = useSiteStore((state) => state.setCompactNavigation);
  const setScheduleDate = useScheduleStore((state) => state.setScheduleDate);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Today's schedule has been updated.");

  const navigate = useNavigate();

  useEffect(() => {
    const handleListener = () => {
      if (!printMode) {
        if (!compactNavigation && window.innerWidth < widthBreakPoint) {
          setCompactNavigation(true);
        } else if (compactNavigation && window.innerWidth >= widthBreakPoint) {
          setCompactNavigation(false);
        }
      }
    };

    window.addEventListener('resize', handleListener);

    return () => {
      window.removeEventListener('resize', handleListener);
    }
  }, [compactNavigation, setCompactNavigation, printMode]);

  const handleDatePick = (newValue) => {
    // This will be a date time object. It is not yet formatted for display.
    setScheduleDate(newValue === true ? DateTime.now() : newValue);

    navigate('schedule');
  };

  const renderInput = ({ inputRef, inputProps, InputProps }) => compactNavigation
    ? <StyledTouchBox ref={inputRef}>{InputProps?.endAdornment}</StyledTouchBox>
    : <StyledBox style={styles.calendarIcon} ref={inputRef}>{InputProps?.endAdornment}</StyledBox>;

  const renderIcon = () => {
    return (
      <Tooltip title="Change the schedule date">
        <CalendarMonthIcon fontSize="large" />
      </Tooltip>
    );
  };

  const closeDrawer = () => {
    setDatePickerIsOpen(false);
    setDrawerOpen(false);
  };

  const handleUpdateSchedule = () => {
    setRefreshing(true);

    fetch(`${api.address}/schedule/update.php?action=refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: authPackage() }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error !== false) {
          console.error(data.errorMessage);

          setSnackbarMessage(data.errorMessage);
        } else if (screenID === 'schedule') {
          // Change the schedule date to today. This will reload the schedule page.
          handleDatePick(true);
        }

        setRefreshing(false);

        setShowSnackbar(true);

        setTimeout(() => {
          setShowSnackbar(false);
        }, 4000);
      });
  };

  if (compactNavigation) {
    return (
      <div style={{ display: printMode ? 'none' : 'flex', ...styles.touchContainer} }>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setDrawerOpen(true)}
              >
                <Menu />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <LogoutIcon onClick={logout} />
            </Toolbar>
          </AppBar>
          <SwipeableDrawer
            anchor="left"
            open={drawerOpen}
            onClose={closeDrawer}
            onOpen={() => setDrawerOpen(true) }
          >
            <Box role="presentation">
              <List>
                <ListItem
                  disablePadding
                  onClick={() => {
                    setDatePickerIsOpen(true);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                          label=""
                          inputFormat="MM/DD/YYYY"
                          value={scheduleDate}
                          open={datePickerIsOpen}
                          onChange={(newValue) => {
                            closeDrawer();
                            handleDatePick(newValue);
                          }}
                          // Adjust the end adornment so that it is larger.
                          components={{ OpenPickerIcon: renderIcon }}
                          // Remove the TextField input element. We want to show only the icon.
                          renderInput={renderInput}
                          showDaysOutsideCurrentMonth={true}
                          disableMaskedInput={true}
                        />
                      </LocalizationProvider>
                    </ListItemIcon>
                    <ListItemText primary="Change schedule date" />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  onClick={() => {
                    closeDrawer();
                    togglePrintMode();
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <PrintIcon sx={styles.touchIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Toggle print mode" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <NavigationLinks
                  handleDatePick={handleDatePick}
                  closeDrawer={closeDrawer}
                  compactNavigation={compactNavigation}
                />
                <Divider />
                <ListItem
                  disablePadding
                  onClick={() => {
                    closeDrawer();
                    navigate('notifications');
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <NotificationIcon sx={styles.touchIcon} />
                    </ListItemIcon>
                    <ListItemText primary="View notifications" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </SwipeableDrawer>
        </Box>
      </div>
    );
  }

  return (
    <div style={{ display: printMode ? 'none' : 'flex', ...styles.container} }>
      <Stack direction="row" spacing={2} style={styles.leftIconStack}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DesktopDatePicker
            label=""
            inputFormat="MM/DD/YYYY"
            value={scheduleDate}
            onChange={handleDatePick}
            // Adjust the end adornment so that it is larger.
            components={{ OpenPickerIcon: renderIcon }}
            // Remove the TextField input element. We want to show only the icon.
            renderInput={renderInput}
            showDaysOutsideCurrentMonth={true}
            disableMaskedInput={true}
          />
        </LocalizationProvider>
        <PrintIcon sx={styles.icon} onClick={togglePrintMode} />
        <PermissionsGate scopes={[SCOPES.canCreate]}>
          <RefreshIcon sx={styles.icon} onClick={handleUpdateSchedule} refreshing={refreshing} />
        </PermissionsGate>
      </Stack>
      <div style={{ flexGrow: 1 }}>
        <NavigationLinks
          handleDatePick={handleDatePick}
          compactNavigation={compactNavigation}
        />
      </div>
      <Stack direction="row" spacing={2} style={styles.rightIconStack}>
        <NotificationIcon sx={styles.icon} />
        <LogoutIcon sx={styles.icon} onClick={logout} />
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        onClose={() => { setShowSnackbar(false) }}
        message={snackbarMessage}
        key="bottomleft"
      />
    </div>
  );
}
