import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import RedoIcon from '@mui/icons-material/Redo';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import api from '../../constants/api';
import useAuthStore from '../../hooks/useAuthStore';

const alertLevels = {
  0: 'success',
  1: 'error',
  2: 'warning',
  3: 'info',
};

const styles = {
  helpIconContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  helpIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    '&:hover': {
      color: (theme) => theme.palette.primary.main,
    },
  },
  helpContainer: {
    padding: '1em',
    position: 'relative',
    margin: '0 3em 4em 3em',
  },
  formContainer: {
    padding: '1em',
    position: 'relative',
    marginBottom: '4em',
  },
  loading: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
  stacks: {
    marginBottom: '1em',
  },
  submitRow: {
    display: 'flex',
    marginTop: '1em',
  },
};

export default function UserForm({ formData, setView, recordData }) {
  const authPackage = useAuthStore((state) => state.getAuthPackage);

  const newRecord = typeof recordData === 'undefined';
  const squads = formData.squads ?? [];
  const roles = formData.roles ?? [];

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [role, setRole] = useState(0);
  const [squad, setSquad] = useState(0);
  const [email, setEmail] = useState('');
  const [active, setActive] = useState(1);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [missingRole, setMissingRole] = useState(false);
  const [helpVisible, setHelpVisibility] = useState(false);

  useEffect(() => {
    if (!newRecord && typeof recordData.userID !== 'undefined') {
      console.debug('Filling the existing user data.');

      setFirstname(recordData.firstname);
      setLastname(recordData.lastname);
      setRole(recordData.roleID);
      setSquad(recordData.squadID === null ? 0 : recordData.squadID);
      setEmail(recordData.email);
      setActive(recordData.active);
    }
  }, [recordData, newRecord]);

  const handleChangeFirstname = (event) => {
    setFirstname(event.target.value);
  };

  const handleChangeLastname = (event) => {
    setLastname(event.target.value);
  };

  const handleChangeSquad = (event) => {
    setSquad(event.target.value);
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);

    setMissingRole(event.target.value === 0);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeActive = (event) => {
    setActive(event.target.value);
  };

  const handleSubmit = () => {
    // Is the role unset?
    if (role === 0) {
      setMissingRole(true);
    } else {
      // Show the loading animation to prevent further values from changing.
      setLoading(true);

      // The role is set. Continue to saving this record.
      const apiAddress = newRecord ? `${api.address}/user/create.php` : `${api.address}/user/update.php`;
      const values = {
        firstname,
        lastname,
        roleID: role,
        squadID: squad,
        email,
        active,
        token: authPackage(),
      };

      if (!newRecord) {
        // Add the user ID if updating a record.
        values.userID = recordData.userID;
      }

      fetch(apiAddress, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      })
        .then(data => data.json())
        .then((data) => {
          if (data.error) {
            setErrorMessage(data.errorMessage);
          } else {
            setView(0);
          }

          setLoading(false);
        });
    }
  };

  const handleDelete = () => {
    console.debug(`The user wants to delete user ${recordData.userID}`);

    setLoading(true);

    fetch(`${api.address}/user/delete.php?userID=${recordData.userID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  }

  const showHelp = () => {
    setHelpVisibility(!helpVisible);
  };

  const newPassword = () => {
    console.debug(`The user wants to reissue a password to user ${recordData.userID}`);

    setLoading(true);

    fetch(`${api.address}/user/update.php?action=resetPassword&userID=${recordData.userID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setSuccessMessage('The password was reset and emailed to the user.');
        }

        setLoading(false);
      });
  };

  return (
    <>
      <Paper elevation={2} sx={styles.formContainer}>
        <Backdrop open={isLoading} sx={styles.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {successMessage !== '' ? <Alert severity="success" sx={{ marginBottom: '1em' }}>{successMessage}</Alert> : null}
        {errorMessage !== '' ? <Alert severity="warning" sx={{ marginBottom: '1em' }}>{errorMessage}</Alert> : null}
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            required
            id="input-firstname"
            label="First name"
            variant="outlined"
            value={firstname}
            onChange={handleChangeFirstname}
          />
          <TextField
            required
            id="input-lastname"
            label="Last name"
            variant="outlined"
            value={lastname}
            onChange={handleChangeLastname}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            required
            id="input-email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleChangeEmail}
            fullWidth
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <Box sx={{ minWidth: 146 }}>
            <FormControl fullWidth>
              <InputLabel id="select-squad-label">Squad</InputLabel>
              <Select
                labelId="select-squad-label"
                id="select-squad"
                value={squad}
                label="Squad"
                onChange={handleChangeSquad}
              >
                <MenuItem value={0}>None</MenuItem>
                {squads.map((squad) => {
                  return (
                      <MenuItem key={squad.squadID} value={squad.squadID}>
                        {squad.callSignGroup === null ? squad.squadOrder : `${squad.callSignGroup} (${squad.squadOrder})`}
                      </MenuItem>
                    );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 146 }}>
            <FormControl fullWidth error={missingRole}>
              <InputLabel id="select-role-label">Website role</InputLabel>
              <Select
                labelId="select-role-label"
                id="select-role"
                value={role}
                label="Website role"
                onChange={handleChangeRole}
                required
              >
                <MenuItem value={0}>None</MenuItem>
                {roles.map((role) => {
                  return <MenuItem key={role.roleID} value={role.roleID}>{role.role}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 146 }}>
            <FormControl fullWidth>
              <InputLabel id="select-active-label">Active</InputLabel>
              <Select
                labelId="select-active-label"
                id="select-active"
                value={active}
                label="Active"
                onChange={handleChangeActive}
                required
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        {role !== 0 ? <Alert severity={alertLevels[role]}>{role > 0 ? formData.roleDescriptions[role] : ''}</Alert> : ''}
        <Box sx={styles.submitRow}>
          {!newRecord ? <Button variant="outlined" onClick={handleDelete} color="error">Delete</Button> : null}
          <div style={styles.helpIconContainer}>
            <Tooltip title="View help">
              <HelpOutlineIcon onClick={showHelp} fontSize="large" sx={styles.helpIcon} />
            </Tooltip>
            <Tooltip title="Send a new password">
              <RedoIcon onClick={newPassword} fontSize="large" sx={{ ...styles.helpIcon, marginLeft: '7px' }} />
            </Tooltip>
          </div>
          <Button variant="outlined" onClick={handleSubmit} alt="Save">Save</Button>
        </Box>
      </Paper>
      <Paper elevation={2} sx={styles.helpContainer} style={{ display: helpVisible ? 'block' : 'none' }}>
        <Typography variant="h5">General tips</Typography>
        <Typography variant="body1" paragraph>
          Each user is a specific person. Do not let multiple people share accounts.
          If someone needs very limited read only access to the schedule, and never needs to to see historic
          data, (like dispatch) give them an API key instead.
        </Typography>
        <Typography variant="h5">Name</Typography>
        <Typography variant="body1" paragraph>The real name of the user. Use proper capitalization.</Typography>
        <Typography variant="h5">Email</Typography>
        <Typography variant="body1" paragraph>
          The email address is used to send the user password after its account is created.
          This must be the person's real email address or they will not receive their password.
        </Typography>
        <Typography variant="h5">Squad</Typography>
        <Typography variant="body1" paragraph>
          This may be left blank, but setting the proper squad is important for the EDITOR role.
          A supervisor will be given the EDITOR role and the ability to edit the employees of the squad you pick for them here.
        </Typography>
        <Typography variant="h5">Role</Typography>
        <Typography variant="body1">The role determines the level of access to this website.</Typography>
        <Alert severity="warning">Be extremely careful with whom you give the OWNER role.</Alert>
        <Typography variant="body1" paragraph>
          It's probably best to have only two people with the OWNER role.
          Maybe the senior parking enforcement supervisor and the owner of this website, Joe Arway.
          The OWNER role is able to edit all of the data on this website: schedules, employees, squads, ranks, and website users.
        </Typography>
        <Typography variant="body1" paragraph>
          The EDITOR role will most likely be a supervisor.
          This role allows the person to edit the schedule and the employees that share the same squad as the user.
          Give this role to any person who needs to be able to edit the schedule.
          If the person is not a supervisor: set their squad to none.
        </Typography>
        <Typography variant="body1" paragraph>
          The VIEWER role is identical to the EDITOR role except the user will not be able to make any changes.
          The user will be able to view the schedule and the employees of the same squad as the user.
        </Typography>
        <Typography variant="h5">Active</Typography>
        <Typography variant="body1" paragraph>
          By setting active to "no" you are able to create new users in anticipation of them getting a promotion.
          After giving them a tutorial on how to use this website you can set their active to "yes" and they will have access.
        </Typography>
        <Typography variant="h5">Send a new password button</Typography>
        <Typography variant="body1" paragraph>
          The user's current password will be reissued and emailed. The email address used will be the one currently saved.
          If you just changed the email address you must save the record first before clicking the button to reissue its password.
        </Typography>
      </Paper>
    </>
  );
}
