import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import api from '../constants/api';
import useAuthStore from '../hooks/useAuthStore';

const styles = {
  container: {
    paddingTop: '4em',
  },
  cardBox: {
    width: '100%',
    height: 320,
    background: 'rgba(0 0 0 / 0.02)',
    position: 'relative',
  },
  header: {
    width: '100%',
    background: 'linear-gradient(90deg, rgba(0 0 0 / 0.12), rgba(0 0 0 / 0.02) 20%)',
    marginBottom: '4em',
    minHeight: '1.2em',
  },
  headerText: {
    color: 'rgba(0 0 0 / 0.7)',
  },
  loading: {
    color: '#fff',
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
  button: {
    marginLeft: '6px',
    marginTop: '8px',
    marginBottom: '1em',
  },
  attribution: {
    marginTop: '9px',
    justifyContent: 'center',
    display: 'flex',
  }
};

export default function Login() {
  const checking = useAuthStore((state) => state.checking);
  const error = useAuthStore((state) => state.error);
  const loading = useAuthStore((state) => state.loading);
  const login = useAuthStore((state) => state.login);
  const userID = useAuthStore((state) => state.userID);
  const setLoading = useAuthStore((state) => state.setLoading);
  const setUserID = useAuthStore((state) => state.setUserID);
  const usingApiKey = useAuthStore((state) => state.usingApiKey);

  const [users, setUsers] = useState([]);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!checking && !usingApiKey) {
      setLoading(true);

      fetch(`${api.address}/user/read.php?action=loginUsers`)
        .then(data => data.json())
        .then((data) => {
          if (data.itemCount > 0) {
            const parsedUrl = new URL(window.location.href);
            let { body } = data;

            if (parsedUrl.searchParams.get('developer') === null) {
              body = body.filter((user) => user.userID !== 1);
            }

            setUsers(body);

            setLoading(false);
          }
        });
    }
  }, [checking, setLoading, usingApiKey]);

  return (
    <>
      <Backdrop open={checking}>
        <CircularProgress color="inherit" />
        <Paper elevation={3} sx={{ marginLeft: '1em', padding: '1em' }}>Checking your authentication token.</Paper>
      </Backdrop>
      <Container maxWidth="sm" sx={styles.container}>
        <Card>
          <Box sx={styles.cardBox}>
            <Backdrop sx={styles.loading} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={styles.header} />
            <FormControl sx={{ marginLeft: '6px', width: '80%' }}>
              <InputLabel id="username-label">User</InputLabel>
              <Select
                labelId="username-label"
                id="username"
                value={userID}
                label="User"
                onChange={(event) => { setUserID(event.target.value) }}
              >
                {users.map((user) => {
                  return <MenuItem key={user.userID} value={user.userID}>{user.label}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ marginLeft: '6px', width: '80%' }}>
              <TextField
                id="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                margin="normal"
                onChange={(event) => { setPassword(event.target.value) }}
                error={error}
              />
            </FormControl>
            <br />
            <Button variant="outlined" sx={styles.button} onClick={() => login({ userID, password })}>Log in</Button>
            <Divider />
            <Typography variant="caption" color="grey" sx={styles.attribution}>
              Written by&nbsp;<Link href="https://github.com/Clearmist">Matthew G. Sanders</Link>
            </Typography>
          </Box>
        </Card>
      </Container>
    </>
  );
}
