import { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useScheduleStore from '../../hooks/useScheduleStore';
import useSiteStore from '../../hooks/useSiteStore';

export default memo(function NotesIcon() {
  const noteVisibility = useScheduleStore((state) => state.noteVisibility);
  const setNoteVisibility = useScheduleStore((state) => state.setNoteVisibility);
  const printMode = useSiteStore((state) => state.printMode);

  if (!printMode) {
    return null;
  }

  return (
    <th className="no-print">
      <Tooltip title={noteVisibility ? 'Hide the notes' : 'View the notes'}>
        <IconButton onClick={() => setNoteVisibility(!noteVisibility)}>
          {noteVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </Tooltip>
    </th>
  );
});
