import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import Tooltip from '@mui/material/Tooltip';

export default function RefreshIcon({ fontSize, onClick, refreshing, sx }) {
  if (refreshing) {
    delete sx.color;

    sx.transform = 'rotate(90deg)';
  }

  return (
    <Tooltip title={refreshing ? 'Refreshing...' : "Refresh today's schedule"}>
      <IconButton onClick={refreshing ? () => {} : onClick}>
        <SyncIcon
          className={refreshing ? 'rotating' : ''}
          fontSize={fontSize || 'large'}
          sx={sx}
          titleAccess="Refresh today's schedule"
          color={refreshing ? 'disabled' : 'inherit'}
        />
      </IconButton>
    </Tooltip>
  );
}

