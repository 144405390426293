export default function calculateCenter(coordinates) {
  const latitude = [];
  const longitude = [];

  coordinates.forEach((coordinate) => {
    latitude.push(coordinate[0]);
    longitude.push(coordinate[1]);
  });

  return [
    latitude.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / latitude.length,
    longitude.reduce((previousValue, currentValue) => previousValue + currentValue, 0) / longitude.length,
  ];
}
