const styles = {
  entry: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '2rem',
  },
  chart: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '600px',
  },
  information: {
    padding: '1.2rem',
    width: '90%',
  },
  input: {
    minWidth: '200px',
  },
  contents: {
    marginTop: '1.4rem',
    marginBottom: '5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    '& ul': {
      paddingTop: 0
    },
    '& a': {
      textDecoration: 'none',
    },
  },
};

export default styles;
