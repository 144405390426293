import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export const useStatusDataStore = create(subscribeWithSelector((set, get) => ({
  chartData1: [],
  chartData2: [],
  chartData3: [],
  chartData4: [],
  chartData5: [],
  resetChartData: () => {
    console.debug('Resetting the chart state.');

    set({
      chartData1: [],
      chartData2: [],
      chartData3: [],
      chartData4: [],
      chartData5: [],
      errors: [],
      sourceData: [],
    });
  },
  setChartData: (id, data) => {
    set({ [`chartData${id}`]: data });
  },
  constants: {},
  setConstants: ((constants) => set({ constants })),
  filters: { lastUpdated: 0 },
  setFilters: (id, property, value) => {
    const { filters } = get();

    if (!Object.prototype.hasOwnProperty.call(filters, id)) {
      filters[id] = {};
    }

    filters[id][property] = value;
    filters.lastUpdated = id;

    set({ filters });
  },
  sourceData: [],
  setSourceData: ((sourceData) => set({ sourceData })),
})));

export const useEmployeeDataStore = create(subscribeWithSelector((set, get) => ({
  chartData1: [],
  chartData2: [],
  chartData3: [],
  resetChartData: () => {
    console.debug('Resetting the chart state.');

    set({
      chartData1: [],
      chartData2: [],
      chartData3: [],
      errors: [],
      sourceData: [],
    });
  },
  setChartData: (id, data) => {
    set({ [`chartData${id}`]: data });
  },
  constants: {},
  setConstants: ((constants) => set({ constants })),
  filters: { lastUpdated: 0 },
  setFilters: (id, property, value) => {
    const { filters } = get();

    if (!Object.prototype.hasOwnProperty.call(filters, id)) {
      filters[id] = {};
    }

    filters[id][property] = value;
    filters.lastUpdated = id;

    set({ filters });
  },
  sourceData: [],
  setSourceData: ((sourceData) => set({ sourceData })),
})));
