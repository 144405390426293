/**
 * Other properties include:
 * minWidth: 170,
 * align: 'right',
 * format: (value) => value.toLocaleString('en-US')
 */
 const columns = [
  {
    id: 'rankDescriptionShort',
    label: 'Rank',
    maxWidth: 'max-content',
  },
  { id: 'pdid', label: 'ID' },
  { id: 'unit', label: 'Unit' },
  {
    id: 'vehicle',
    label: 'Vehicle',
    maxWidth: '40px',
  },
  {
    id: 'radio',
    label: 'Radio',
    maxWidth: '60px',
    minWidth: '60px',
  },
  {
    id: 'statusID',
    label: 'Status',
    maxWidth: '220px',
  },
  {
    id: 'name',
    label: 'Name',
    minWidth: 'max-content',
  },
  {
    id: 'assignmentID',
    label: 'Assignment',
    minWidth: '300px',
  },
  {
    id: 'adjustedHours',
    label: 'Adjusted',
    minWidth: '80px',
    maxWidth: '80px',
  },
];

export default columns;
