import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import useAuthStore from '../../hooks/useAuthStore';

export default function LogoutIcon({ sx }) {
  const logout = useAuthStore((state) => state.logout);

  return (
    <Tooltip title="Logout">
      <IconButton
        onClick={logout}
        color="inherit"
      >
        <Logout fontSize="large" sx={sx} titleAccess="Logout" />
      </IconButton>
    </Tooltip>
  );
};
