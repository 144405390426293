import create from 'zustand';
import useAuthStore from './useAuthStore';
import { isMobile } from '../constants/userDevice';

const { authorized, role } = useAuthStore.getState();

const useSiteStore = create((set, get) => ({
  compactNavigation: isMobile || window.innerWidth < 900,
  setCompactNavigation: (compactNavigation) => set({ compactNavigation }),
  printMode: authorized && role === 'VIEWER',
  setPrintMode: (printMode) => set({ printMode }),
  screenID: 'schedule',
  setScreenID: (screenID) => set({ screenID }),
}));

const unsubscribe = useAuthStore.subscribe(({ authorized, role }) => {
  if (authorized && role === 'VIEWER') {
    useSiteStore.setState({ printMode: true });
    unsubscribe();
  }
});

export default useSiteStore;
