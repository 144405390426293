import React from 'react';
import TableRow from '@mui/material/TableRow';
import AssignmentTags from './AssignmentTags';
import StatusTags from './StatusTags';
import TableCell from '@mui/material/TableCell';
import TextInput from './TextInput';
import useSiteStore from '../../hooks/useSiteStore';
import columns from '../../constants/scheduleColumns';

const styles = {
  cellBorder: {
    borderBottom: 'none',
    paddingBottom: 0,
  },
};

export default function EmployeeRow({ employee }) {
  const printMode = useSiteStore((state) => state.printMode);

  return (
    <React.Fragment>
      <TableRow>
        {columns.map((column) => {
          let value = employee[column.id];
          const maxWidth = column.maxWidth ?? null;
          const minWidth = column.minWidth ?? null;

          if (column.id === 'name') {
            value = `${employee.lastname}, ${employee.firstname}`;
          } else if (column.id === 'vehicle') {
            value = <TextInput
              employeeID={employee.employeeID}
              employeeValue={employee.vehicle}
              maxLength={4}
              maxWidth={maxWidth}
              updateField="vehicle"
              valueType="number"
              autoCorrectOptions={{ numberField: true }}
            />;
          } else if (column.id === 'radio') {
            value = <TextInput
              employeeID={employee.employeeID}
              employeeValue={employee.radio}
              maxLength={6}
              maxWidth={maxWidth}
              updateField="radio"
              valueType="number"
              autoCorrectOptions={{ numberField: true }}
            />;
          } else if (column.id === 'adjustedHours') {
            value = <TextInput
              employeeID={employee.employeeID}
              employeeValue={employee.adjustedHours}
              maxLength={11}
              maxWidth={maxWidth}
              updateField="adjustedHours"
              valueType="string"
              autoCorrectOptions={{ hoursField: true }}
            />;
          } else if (column.id === 'statusID') {
            value = <StatusTags employeeID={employee.employeeID} />
          } else if (column.id === 'assignmentID') {
            value = <AssignmentTags employeeID={employee.employeeID} />
          }

          return (
            <TableCell
              key={`${employee.employeeID}${column.id}`}
              align={column.align}
              sx={{ maxWidth: maxWidth, minWidth: minWidth, ...!printMode ? styles.cellBorder : {} }}
            >
              {value}
            </TableCell>
          );
        })}
      </TableRow>
      <TextInput
        employeeID={employee.employeeID}
        employeeValue={employee.note}
        maxLength={1000}
        maxWidth="100%"
        textAlign="auto"
        placeholder="Notes"
        updateField="note"
        autoCorrectOptions={{ noteField: true }}
        columnsLength={columns.length}
      />
    </React.Fragment>
  );
}
