import BarChartIcon from '@mui/icons-material/BarChart';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PieChartIcon from '@mui/icons-material/PieChart';
import TableChartIcon from '@mui/icons-material/TableChart';

export const employees = [
  {
    link: 'squad-size',
    name: 'The size of each squad',
    icon: <PieChartIcon />,
  },
  {
    link: 'squad-active-days',
    name: 'Employee active days by squad',
    icon: <TableChartIcon />,
  },
];

export const statuses = [
  {
    link: 'most-common',
    name: 'The most commonly used statuses',
    icon: <PieChartIcon />,
  },
  {
    link: 'most-hours-by-status',
    name: 'The employees with the most number of hours in this status',
    icon: <BarChartIcon />,
  },
  {
    link: 'overtime-spent-per-squad',
    name: 'The amount of overtime hours spent by each squad',
    icon: <TableChartIcon />,
  },
  {
    link: 'most-split-days',
    name: 'The number of days with split-day statuses by employee',
    icon: <BarChartIcon />,
  },
  {
    link: 'split-day-calendar',
    name: 'Employee split-day status calendar',
    icon: <DateRangeIcon />,
  },
];
