import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import api from '../../constants/api';
import useAuthStore from '../../hooks/useAuthStore';

const styles = {
  loading: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
  stacks: {
    marginBottom: '1em',
  },
  submitRow: {
    display: 'flex',
    marginTop: '1em',
  },
  helpContainer: {
    padding: '1em',
    position: 'relative',
    margin: '0 3em 4em 3em',
  },
  helpIconContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  helpIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    '&:hover': {
      color: (theme) => theme.palette.primary.main,
    },
  },
};

export default function Form({ formData, setView, recordData }) {
  const authPackage = useAuthStore((state) => state.getAuthPackage);

  const stations = formData.stations ?? [];
  const newRecord = typeof recordData === 'undefined';

  const [squadOrder, setSquadOrder] = useState('');
  const [callSignGroup, setCallSignGroup] = useState('');
  const [daysOff, setDaysOff] = useState('');
  const [stationID, setStationID] = useState(0);
  const [workingHours, setWorkingHours] = useState('');
  const [active, setActive] = useState(1);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [helpVisible, setHelpVisibility] = useState(false);

  useEffect(() => {
    if (!newRecord && typeof recordData.squadID !== 'undefined') {
      console.debug('Filling the existing squad data.');

      setStationID(recordData.stationID);
      setSquadOrder(recordData.squadOrder);
      setCallSignGroup(recordData.callSignGroup);
      setDaysOff(recordData.daysOff);
      setWorkingHours(recordData.workingHours);
      setActive(recordData.active);
    }
  }, [recordData, newRecord]);

  const handleChangeStation = (event) => {
    setStationID(event.target.value);
  };

  const handleChangeDaysOff = (event) => {
    setDaysOff(event.target.value);
  };

  const handleChangeWorkingHours = (event) => {
    setWorkingHours(event.target.value);
  };

  const handleChangeActive = (event) => {
    setActive(event.target.value);
  };

  const handleSubmit = () => {
    // Show the loading animation to prevent further values from changing.
    setLoading(true);

    // The role is set. Continue to saving this record.
    const apiAddress = newRecord ? `${api.address}/squad/create.php` : `${api.address}/squad/update.php`;
    const values = {
      squadOrder,
      callSignGroup,
      daysOff,
      stationID,
      workingHours,
      active,
      token: authPackage(),
    };

    if (!newRecord) {
      // Add the squad ID if updating a record.
      values.squadID = recordData.squadID;
    }

    fetch(apiAddress, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  };

  const handleDelete = () => {
    console.debug(`The user wants to delete squad ${recordData.squadID}`);

    setLoading(true);

    fetch(`${api.address}/squad/delete.php?squadID=${recordData.squadID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  }

  const buildSummary = () => {
    let summary = 'Summary: ';
    const letter = squadOrder !== '' ? ` (${squadOrder}) ` : '';

    if (callSignGroup !== '') {
      summary += `${callSignGroup}${letter}squad`;

      if (stationID !== 0) {
        summary += ` at ${stations.filter((station) => station.stationID === stationID)[0].stationDescription}`;
      }

      if (workingHours !== '') {
        summary += ` working ${workingHours}`;
      }

      if (daysOff !== '') {
        summary += ` with ${daysOff} off`;
      }
    }

    return summary;
  };

  const showHelp = () => {
    setHelpVisibility(!helpVisible);
  };

  return (
    <>
      <Paper elevation={2} sx={styles.helpContainer}>
        <Backdrop open={isLoading} sx={styles.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {errorMessage !== '' ? <Alert severity="warning" sx={{ marginBottom: '1em' }}>{errorMessage}</Alert> : null}
        <Typography>{buildSummary()}</Typography>
        <Divider sx={{ marginTop: '1em', marginBottom: '2em' }} />
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            required
            id="input-squadOrder"
            label="Squad order"
            variant="outlined"
            value={squadOrder}
            onChange={(event) => setSquadOrder(event.target.value)}
          />
          <TextField
            required
            id="input-callSignGroup"
            label="Call sign"
            variant="outlined"
            value={callSignGroup}
            onChange={(event) => setCallSignGroup(event.target.value)}
          />
          <Box sx={{ minWidth: 146 }}>
            <FormControl fullWidth>
              <InputLabel id="select-station-label">Station</InputLabel>
              <Select
                labelId="select-station-label"
                id="select-station"
                value={stationID}
                label="Station"
                onChange={handleChangeStation}
                required
              >
                <MenuItem value={0}>None</MenuItem>
                {stations.map((station) => {
                  return (
                    <MenuItem key={station.stationID} value={station.stationID}>
                      {station.stationDescription}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            required
            id="input-daysOff"
            label="Days off"
            variant="outlined"
            value={daysOff}
            onChange={handleChangeDaysOff}
          />
          <TextField
            required
            id="input-workingHours"
            label="Working hours"
            variant="outlined"
            value={workingHours}
            onChange={handleChangeWorkingHours}
          />
          <Box sx={{ minWidth: 146 }}>
            <FormControl fullWidth>
              <InputLabel id="select-active-label">Active</InputLabel>
              <Select
                labelId="select-active-label"
                id="select-active"
                value={active}
                label="Active"
                onChange={handleChangeActive}
                required
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Box sx={styles.submitRow}>
          {!newRecord ? <Button variant="outlined" onClick={handleDelete} color="error">Delete</Button> : null}
          <div style={styles.helpIconContainer}>
            <Tooltip title="View help">
              <HelpOutlineIcon onClick={showHelp} fontSize="large" sx={styles.helpIcon} alt="Open help" />
            </Tooltip>
          </div>
          <Button variant="outlined" onClick={handleSubmit}>Save</Button>
        </Box>
      </Paper>
      <Paper elevation={2} sx={styles.helpContainer} style={{ display: helpVisible ? 'block' : 'none' }}>
        <Typography variant="h5">General</Typography>
        <Typography variant="body1" paragraph>
          A squad represents a group on the schedule. These will be used to group employees on the schedule.
        </Typography>
        <Typography variant="h5">Squad order</Typography>
        <Typography variant="body1" paragraph>
          This can be anything. Historically this has been a single letter assigned to the squad.
          The squad orders will be sorted alphabetically in the schedule. This determines the order of the squads.
        </Typography>
        <Typography variant="h5">Call sign</Typography>
        <Typography variant="body1" paragraph>
          This is a four digit number divisible by 10 between 3200 and 3290.
          Employees within this squad are assigned call signs within the 10 numbers of this call sign.
          For example, giving this squad the call sign of 3260 means each employee assigned to
          this squad will have a all sign between 3260 and 3269.
        </Typography>
        <Typography variant="body1" paragraph>
          However, all of that is convention and is subject to be changed.
          The call sign is simply what an officer uses on the radio to identify themselves and can be an arbitrary number.
        </Typography>
        <Typography variant="h5">Station</Typography>
        <Typography variant="body1" paragraph>
          This is the station to which this squad is currently assigned.
          This must be updated every few months after the squads rotate stations.
        </Typography>
        <Typography variant="h5">Days off</Typography>
        <Typography variant="body1" paragraph>
          This should be a three character string of letters representing the days of the week this squad does not work.
          For example, SSM means the squad does not work Saturday, Sunday, and Monday.
          This will be automatically corrected to all upper-case letters after saving.
        </Typography>
        <Typography variant="h5">Working hours</Typography>
        <Typography variant="body1" paragraph>
          This represents the hours the members of this squad work.
          Type in 24 hour format. Extra spaces will be removed after saving.
          For example, a squad working from 0700 to 1730 will have "0700-1730" in this field.
        </Typography>
        <Typography variant="h5">Active</Typography>
        <Typography variant="body1" paragraph>
          By setting active to "no" you can create new squads in this website now in anticipation
          of them being created in real life. After these forms are officially formed simply set active to "yes".
        </Typography>
      </Paper>
    </>
  );
}
