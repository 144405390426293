import { useEffect } from 'react';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import notifications from './data';
import api from '../../constants/api';
import useAuthStore from '../../hooks/useAuthStore';
import useNotificationStore from '../../hooks/useNotificationStore';

function NewBadge({ newCard, children }) {
  if (newCard) {
    return (
      <Badge badgeContent={"new"} color="primary">
        <Typography sx={{ fontSize: 14, paddingRight: '1.3rem' }} color="text.secondary" gutterBottom>
          {children}
        </Typography>
      </Badge>
    );
  }

  return (
    <Typography sx={{ fontSize: 14, paddingRight: '1.3rem' }} color="text.secondary" gutterBottom>
      {children}
    </Typography>
  );
}

function InformationCard({ newCard, notification }) {
  const {
    date,
    page,
    summary,
    Component,
  } = notification;

  return (
    <Card sx={{ marginTop: '3rem' }} elevation={6}>
      <CardContent>
        <NewBadge newCard={newCard}>
          {date}
        </NewBadge>
        <Typography variant="h5" component="div">
          {page}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {summary}
        </Typography>
        <Divider sx={{ margin: '2rem 0' }} />
        <Component />
      </CardContent>
    </Card>
  );
}

export default function Notifications() {
  const authPackage = useAuthStore((state) => state.getAuthPackage);
  const notificationID = useNotificationStore((state) => state.notificationID);
  const setNotificationID = useNotificationStore((state) => state.setNotificationID);
  const userID = useAuthStore((state) => state.userID);

  useEffect(() => {
    if (notificationID !== notifications[0].notificationID) {
      const token = authPackage();

      fetch(`${api.address}/user/update.php?action=setNotificationID&userID=${userID}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          notificationID: notifications[0].notificationID,
          token,
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.error) {
            console.error(data.errorMessage);
          }

          setNotificationID(notifications[0].notificationID);
        });
    }
  });

  return (
    <Container maxWidth="md">
      <Box sx={{ height: '100vh' }}>
        {notifications.map((notification) => {
          return (
            <InformationCard
              key={notification.notificationID}
              notification={notification}
              newCard={notification.notificationID > notificationID}
            />
          );
        })}
      </Box>
    </Container>
  );
}

export {
  notifications,
};
