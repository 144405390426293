import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useStatusDataStore } from '../../../hooks/useDataStore';
import { Bar } from '../charts';
import styles from '../styles';

const sumHours = (o) => {
  const entry = { ...o };

  delete entry.employee;

  return Object.values(entry).reduce((a, b) => a + b);
}

export default function Chart2() {
  const chartData = useStatusDataStore((state) => state.chartData2);
  const abbreviationConstants = useStatusDataStore((state) => state.constants.statusAbbreviation);
  const employees = useStatusDataStore((state) => state.constants.employee);
  const setChartData = useStatusDataStore((state) => state.setChartData);
  const setFilters = useStatusDataStore((state) => state.setFilters);
  const squad = useStatusDataStore((state) => state.constants.squad);

  const [timeRange, setTimeRange] = useState('two-years');
  const [statusAbbreviationID, setStatusAbbreviationID] = useState(0);
  const [squadSelection, setSquadSelection] = useState(0);
  const [keys, setKeys] = useState([]);

  const statusAbbreviations = typeof abbreviationConstants !== 'undefined'
    ? Object.values(abbreviationConstants).sort((a, b) => `${a.statusAbbreviation}`.localeCompare(`${b.statusAbbreviation}`))
    : [];

  const squads = typeof squad !== 'undefined'
    ? Object.values(squad).sort((a, b) => `${a.callSignGroup}`.localeCompare(`${b.callSignGroup}`))
    : [];

  const handleChange = ({ target }, filterName) => {
    if (filterName === 'time-range') {
      setTimeRange(target.value);
    } else if (filterName === 'status-abbreviation-id') {
      setStatusAbbreviationID(target.value);
    } else if (filterName === 'squad-selection') {
      setSquadSelection(target.value);
    }

    setChartData(2, []);
    setFilters(2, filterName, target.value);
  };

  useEffect(() => {
    setFilters(2, 'status-abbreviation-id', statusAbbreviationID);
  }, []);

  useEffect(() => {
    if (typeof employees !== 'undefined') {
      const statusTypes = [];

      chartData.forEach((entry) => {
        Object.keys(entry).forEach((k) => {
          if (k !== 'employee' && !statusTypes.includes(k)) {
            statusTypes.push(k);
          }
        })
      });

      setKeys(statusTypes.sort());
    }
  }, [chartData, employees]);

  const sorted = Object.values(chartData)
    .filter((entry) => sumHours(entry) > 0)
    .sort((a, b) => sumHours(b) - sumHours(a));

  const data = squadSelection === 0 ? sorted.slice(0, 10) : sorted;

  return (
    <Container maxWidth={false} sx={styles.entry}>
      <Stack direction="row" sx={{ alignSelf: 'start' }}>
        <Typography variant="h3" id="most-hours-by-status">
          The employees with the most number of hours in this status
        </Typography>
        <Link href="#most-hours-by-status">
          <IconButton aria-label="link to this chart" color="secondary">
            <LinkIcon fontSize="large" />
          </IconButton>
        </Link>
      </Stack>
      <Container maxWidth={false} sx={styles.chart}>
        {chartData.length > 0
          ? <Bar
              axisBottomLegend="employee"
              axisLeftLegend="hours"
              data={data}
              indexBy="employee"
              keys={keys}
            />
          : <Skeleton variant="rectangular" width={600} height={600} />}
      </Container>
      <Paper elevation={4} sx={styles.information}>
        <FormControl>
          <InputLabel id="2-time-range-label">time range</InputLabel>
          <Select
            labelId="2-time-range-label"
            id="2-time-range"
            value={timeRange}
            label="Time range"
            onChange={(event) => handleChange(event, 'time-range')}
            sx={styles.input}
          >
            <MenuItem value="two-years">Previous two years</MenuItem>
            <MenuItem value="one-year">Previous year</MenuItem>
            <MenuItem value="six-months">Previous 6 months</MenuItem>
            <MenuItem value="three-months">Previous 3 months</MenuItem>
            <MenuItem value="previous-month">Previous month</MenuItem>
            <MenuItem value="year-start">Since January 1st</MenuItem>
            <MenuItem value="this-month">This month</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '1rem' }}>
          <InputLabel id="2-status-abbreviation-id-label">status</InputLabel>
          <Select
            labelId="2-status-abbreviation-id-label"
            id="2-status-abbreviation-id"
            value={statusAbbreviationID}
            label="status"
            onChange={(event) => handleChange(event, 'status-abbreviation-id')}
            sx={styles.input}
          >
            <MenuItem value={0}><span style={{ fontStyle: 'italic' }}>All</span></MenuItem>
            {statusAbbreviations.map((abbreviation) => <MenuItem value={abbreviation.statusAbbreviationID} key={abbreviation.statusAbbreviationID}>{abbreviation.statusAbbreviation}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '1rem' }}>
          <InputLabel id="2-squad-selection">who</InputLabel>
          <Select
            labelId="2-squad-selection"
            id="2-squad-selection"
            value={squadSelection}
            label="Who"
            onChange={(event) => handleChange(event, 'squad-selection')}
            sx={styles.input}
          >
            <MenuItem value={0}>Top 10</MenuItem>
            {squads.map((squad) => <MenuItem value={squad.squadID} key={squad.squadID}>{squad.callSignGroup}</MenuItem>)}
          </Select>
        </FormControl>
      </Paper>
      <Paper elevation={4} sx={styles.information}>
        These are the employees who have the most number of hours in a given status type. You can choose to show either the top 10 of all employees or filter by squad.
      </Paper>
    </Container>
  );
}
