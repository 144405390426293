import { DateTime } from 'luxon';
import { useEmployeeDataStore } from '../../../hooks/useDataStore';

const today = DateTime.fromISO(DateTime.now().toISODate());

const statusTypes = [];

function setDateLimit(filters) {
  let dateLimit = false;

  if (Object.prototype.hasOwnProperty.call(filters, 'time-range')) {
    if (filters['time-range'] === 'two-years') {
      dateLimit = today.minus({ year: 2 }).toISODate();
    } else if (filters['time-range'] === 'one-year') {
      dateLimit = today.minus({ year: 1 }).toISODate();
    } else if (filters['time-range'] === 'six-months') {
      dateLimit = today.minus({ month: 6 }).toISODate();
    } else if (filters['time-range'] === 'three-months') {
      dateLimit = today.minus({ month: 3 }).toISODate();
    } else if (filters['time-range'] === 'previous-month') {
      dateLimit = today.minus({ month: 1 }).toISODate();
    } else if (filters['time-range'] === 'year-start') {
      dateLimit = today.set({ month: 1, day: 1 }).toISODate();
    } else if (filters['time-range'] === 'this-month') {
      dateLimit = today.set({ day: 1 }).toISODate();
    } else if (filters['time-range'] === 'today') {
      dateLimit = today.toISODate();
    }
  }

  return dateLimit;
}

const calculations = {
  calculateChart1: (sourceData, constants, filters = {}) => {
    const chartData = [];
    const counts = {};

    sourceData.forEach((entry) => {
      if (entry.squadID === 1) {
        return false;
      }

      if (typeof counts[entry.squadID] === 'undefined') {
        counts[entry.squadID] = {};
      }

      if (typeof counts[entry.squadID][entry.rankID] === 'undefined') {
        counts[entry.squadID][entry.rankID] = 0;
      }

      counts[entry.squadID][entry.rankID] += 1;
    });

    Object.entries(counts).forEach(([squadID, ranks]) => {
      const squadData = [];

      Object.entries(ranks).forEach(([rankID, count]) => {
        squadData.push({
          x: constants.rank[rankID].rankDescriptionShort,
          y: count,
        });
      });

      // Sort these statuses alphabetically.
      squadData.sort((a, b) => {
        if (a.x === 'PES') {
          return -1;
        }

        if (a.x === 'PEO2' || a.x === 'PEOII') {
          return -1;
        }

        return a.x.localeCompare(b.x);
      });

      chartData.push({
        id: constants.squad[squadID].callSignGroup,
        data: squadData,
      });
    });

    chartData.sort((a, b) => a.id - b.id);

    return chartData;
  },
  calculateChart2: (sourceData, constants, filters = {}) => {
    const counts = {};
    const data = {
      name: 'seniority',
      children: [],
    };
    let totalDays = 0;

    sourceData.forEach((entry) => {
      if (entry.squadID === null) {
        console.log(entry);
      }
      if (entry.daysActive === '') {
        return false;
      }

      if (typeof counts[entry.squadID] === 'undefined') {
        counts[entry.squadID] = {};
      }

      if (typeof counts[entry.squadID][entry.employeeID] === 'undefined') {
        counts[entry.squadID][entry.employeeID] = 0;
      }

      counts[entry.squadID][entry.employeeID] = {
        name: `${entry.firstname} ${entry.lastname}`,
        days: entry.daysActive,
      };

      totalDays += entry.daysActive;
    });

    Object.entries(counts).forEach(([squadID, squad]) => {
      const name = squadID === '1' ? 'administration' : `${constants.squad[squadID].callSignGroup}`;
      const squadInformation = {
        name,
        children: [],
      };

      Object.entries(squad).forEach(([employeeID, obj]) => {
        squadInformation.children.push(obj);
      });

      data.children.push(squadInformation);
    });

    console.debug(`[2] The total number of active days is ${totalDays}.`);

    return data;
  },
};

useEmployeeDataStore.subscribe((state) => JSON.stringify(state.filters), (stringified) => {
  const { constants, setChartData, sourceData } = useEmployeeDataStore.getState();
  const filters = JSON.parse(stringified);

  if (filters.lastUpdated !== 0) {
    console.debug(`Calculating chart ${filters.lastUpdated} for the employee page.`);

    let chartData = [];

    chartData = calculations[`calculateChart${filters.lastUpdated}`](sourceData, constants, filters[filters.lastUpdated]);

    setChartData(filters.lastUpdated, chartData);
  }
});

useEmployeeDataStore.subscribe((state) => state.sourceData, (sourceData) => {
  const { constants, setChartData } = useEmployeeDataStore.getState();

  if (sourceData.length > 0) {
    console.debug('Calculating all chart data for the employee data page.');

    Object.keys(calculations).forEach((calculation) => {
      setChartData(calculation.replace('calculateChart', ''), calculations[calculation](sourceData, constants));
    });

    statusTypes.sort();
  }
});
