import { DateTime } from 'luxon';
import { useStatusDataStore } from '../../../hooks/useDataStore';

const today = DateTime.fromISO(DateTime.now().toISODate());

const statusTypes = [];

function setDateLimit(filters) {
  let dateLimit = false;

  if (Object.prototype.hasOwnProperty.call(filters, 'time-range')) {
    if (filters['time-range'] === 'two-years') {
      dateLimit = today.minus({ year: 2 }).toISODate();
    } else if (filters['time-range'] === 'one-year') {
      dateLimit = today.minus({ year: 1 }).toISODate();
    } else if (filters['time-range'] === 'six-months') {
      dateLimit = today.minus({ month: 6 }).toISODate();
    } else if (filters['time-range'] === 'three-months') {
      dateLimit = today.minus({ month: 3 }).toISODate();
    } else if (filters['time-range'] === 'previous-month') {
      dateLimit = today.minus({ month: 1 }).toISODate();
    } else if (filters['time-range'] === 'year-start') {
      dateLimit = today.set({ month: 1, day: 1 }).toISODate();
    } else if (filters['time-range'] === 'this-month') {
      dateLimit = today.set({ day: 1 }).toISODate();
    } else if (filters['time-range'] === 'today') {
      dateLimit = today.toISODate();
    }
  }

  return dateLimit;
}

const calculations = {
  calculateChart1 : (sourceData, constants, filters = {}) => {
    const chartData = [];
    const counts = {};
    const dateLimit = setDateLimit(filters);
    const employeeID = filters['employee-id'] ??= 0;

    sourceData.forEach((entry) => {
      if (typeof constants.status[entry.statusID] === 'undefined') {
        console.warn(`The ${entry.statusID} status ID does not exist in the constants object.`);
        return false;
      }

      const { statusAbbreviationID } = constants.status[entry.statusID];

      if (typeof constants.statusAbbreviation[statusAbbreviationID] === 'undefined') {
        console.warn(`The ${statusAbbreviationID} status abbreviation ID does not exist in the constants object.`);
        return false;
      }

      const { statusAbbreviation } = constants.statusAbbreviation[statusAbbreviationID];

      if (dateLimit && entry.scheduleDate < dateLimit) {
        return false;
      }

      if (employeeID !== 0 && entry.employeeID !== employeeID) {
        return false;
      }

      if (typeof counts[statusAbbreviation] === 'undefined') {
        counts[statusAbbreviation] = 0;
      }

      counts[statusAbbreviation] += 1;
    });

    Object.entries(counts).forEach(([label, value]) => {
      chartData.push({
        id: label,
        label,
        value,
      });

      if (!statusTypes.includes(label)) {
        statusTypes.push(label);
      }
    });

    // Sort these statuses alphabetically.
    chartData.sort((a, b) => a.id.localeCompare(b.id));

    return chartData;
  },
  calculateChart2: (sourceData, constants, filters = {}) => {
    const counts = {};
    const dateLimit = setDateLimit(filters);
    const abbreviationFilter = filters['status-abbreviation-id'] ??= 0;
    const squadFilter = filters['squad-selection'] ??= 0;

    sourceData.forEach((entry) => {
      if (dateLimit && entry.scheduleDate < dateLimit) {
        return false;
      }

      if (squadFilter !== 0 && entry.squadID !== squadFilter) {
        return false;
      }

      const { hourAmount, statusAbbreviationID } = constants.status[entry.statusID];

      if (abbreviationFilter !== 0 && statusAbbreviationID !== abbreviationFilter) {
        return false;
      }

      const { statusAbbreviation } = constants.statusAbbreviation[statusAbbreviationID];

      if (typeof counts[entry.employeeID] === 'undefined') {
        counts[entry.employeeID] = {
          employee: `${entry.firstname} ${entry.lastname}`,
        };
      }

      if (typeof counts[entry.employeeID][statusAbbreviation] === 'undefined') {
        counts[entry.employeeID][statusAbbreviation] = 0;
      }

      counts[entry.employeeID][statusAbbreviation] += hourAmount ?? 10;
    });

    return Object.values(counts);
  },
  calculateChart3: (sourceData, constants, filters = {}) => {
    const counts = {};
    const data = {
      name: 'overtime',
      children: [],
    };
    const dateLimit = setDateLimit(filters);
    let totalHours = 0;

    sourceData.forEach((entry) => {
      if (dateLimit && entry.scheduleDate < dateLimit) {
        return false;
      }

      const { hourAmount, statusAbbreviationID } = constants.status[entry.statusID];

      // Keep only the overtime status.
      if (statusAbbreviationID !== 18) {
        return false;
      }

      if (typeof counts[entry.squadID] === 'undefined') {
        counts[entry.squadID] = {};
      }

      if (typeof counts[entry.squadID][entry.employeeID] === 'undefined') {
        counts[entry.squadID][entry.employeeID] = 0;
      }

      counts[entry.squadID][entry.employeeID] += hourAmount;
    });

    Object.entries(counts).forEach(([squadID, squad]) => {
      const squadInformation = {
        name: `${constants.squad[squadID].callSignGroup}`,
        children: [],
      };

      Object.entries(squad).forEach(([employeeID, hours]) => {
        if (typeof constants.employee[employeeID] === 'undefined') {
          console.error(`Employee ID ${employeeID} is not in the employee object. ${constants.squad[squadID].callSignGroup} (${squadID}) squad.`);
        } else {
          squadInformation.children.push({
            name: `${constants.employee[employeeID].firstname} ${constants.employee[employeeID].lastname}`,
            hours,
          });

          totalHours += hours;
        }
      });

      data.children.push(squadInformation);
    });

    console.debug(`[3] The date limit is ${dateLimit}. The total number of overtime hours is ${totalHours}.`);

    return data;
  },
  calculateChart4: (sourceData, constants, filters = {}) => {
    const counts = {};
    const dateLimit = setDateLimit(typeof filters['time-range'] === 'undefined' ? { 'time-range': 'three-months' } : filters);
    const abbreviationFilter = filters['status-abbreviation-id'] ??= 0;

    sourceData.forEach((entry) => {
      if (dateLimit && entry.scheduleDate < dateLimit) {
        // Ignore records outside of our date range.
        return false;
      }

      const { hourAmount, statusAbbreviationID } = constants.status[entry.statusID];

      if (hourAmount === null || hourAmount === 10) {
        // Ignore statuses that are full 10 hours.
        return false;
      }

      if (abbreviationFilter !== 0 && statusAbbreviationID !== abbreviationFilter) {
        // Filter out unwanted statuses if a status filter is set.
        return false;
      }

      const { statusAbbreviation } = constants.statusAbbreviation[statusAbbreviationID];

      if (typeof counts[entry.employeeID] === 'undefined') {
        counts[entry.employeeID] = {
          employee: `${entry.lastname}, ${entry.firstname}`,
        };
      }

      if (typeof counts[entry.employeeID][statusAbbreviation] === 'undefined') {
        counts[entry.employeeID][statusAbbreviation] = 0;
      }

      counts[entry.employeeID][statusAbbreviation] += 1;
    });

    return Object.values(counts);
  },
  calculateChart5 : (sourceData, constants, filters = {}) => {
    const chartData = [];
    const dateLimit = setDateLimit(filters);
    const employeeIDFilter = filters['employee-id'] ?? 0;
    const statusAbbreviationIDFilter = filters['status-abbreviation-id'] ?? 0;

    if (employeeIDFilter === 0) {
      // An employee filter must be set by the user.
      return [];
    }

    sourceData.forEach((entry) => {
      if (entry.employeeID !== employeeIDFilter) {
        return false;
      }

      if (typeof constants.status[entry.statusID] === 'undefined') {
        console.warn(`The ${entry.statusID} status ID does not exist in the constants object.`);
        return false;
      }

      const { hourAmount, statusAbbreviationID, statusDescription } = constants.status[entry.statusID];

      if (hourAmount === null || hourAmount === 10) {
        return false;
      }

      if (statusAbbreviationIDFilter !== 0 && statusAbbreviationIDFilter !== statusAbbreviationID) {
        return false;
      }

      if (dateLimit && entry.scheduleDate < dateLimit) {
        return false;
      }

      chartData.push({
        value: hourAmount,
        day: entry.scheduleDate,
        statusDescription,
      });
    });

    return chartData;
  },
};

useStatusDataStore.subscribe((state) => JSON.stringify(state.filters), (stringified) => {
  const { constants, setChartData, sourceData } = useStatusDataStore.getState();
  const filters = JSON.parse(stringified);

  if (filters.lastUpdated !== 0) {
    console.debug(`Calculating chart ${filters.lastUpdated} for the status page.`);

    let chartData = [];

    if (typeof calculations[`calculateChart${filters.lastUpdated}`] === 'function') {
      chartData = calculations[`calculateChart${filters.lastUpdated}`](sourceData, constants, filters[filters.lastUpdated]);
    } else {
      console.error(`calculateChart${filters.lastUpdated} is not a function within the calculations object.`);
    }

    setChartData(filters.lastUpdated, chartData);
  }
});

useStatusDataStore.subscribe((state) => state.sourceData, (sourceData) => {
  const { constants, setChartData } = useStatusDataStore.getState();

  if (sourceData.length > 0) {
    console.debug('Calculating all chart data for the status page.');

    Object.keys(calculations).forEach((calculation) => {
      setChartData(calculation.replace('calculateChart', ''), calculations[calculation](sourceData, constants));
    });

    statusTypes.sort();
  }
});
