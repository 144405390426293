import { useCallback, useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TextField  from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import api from '../../constants/api.js';
import useAuthStore from '../../hooks/useAuthStore';
import useScheduleStore from '../../hooks/useScheduleStore.js';
import useSiteStore from '../../hooks/useSiteStore.js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AssignmentTags({ employeeID }) {
  const assignmentMap = useScheduleStore(useCallback((state) => state.assignmentMap[employeeID], [employeeID]));
  const updateAssignmentMap = useScheduleStore((state) => state.updateAssignmentMap);
  const assignments = useScheduleStore((state) => state.assignments);
  const authPackage = useAuthStore((state) => state.getAuthPackage);
  const isOlderSchedule = useScheduleStore((state) => state.isOlderSchedule);
  const printMode = useSiteStore((state) => state.printMode);
  const scheduleDate = useScheduleStore((state) => state.scheduleDate);

  const [value, setValue] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const printDate = scheduleDate.toISODate();

  useEffect(() => {
    setValue(Array.isArray(assignmentMap) && assignmentMap.length > 0 ? assignmentMap : []);
  }, [printDate, assignmentMap]);

  const styles = {
    container: {
      position: 'relative',
    },
    loading: {
      zIndex: (theme) => theme.zIndex.drawer + 1,
      position: 'absolute',
    },
    autocomplete: {
      width: 'auto',
    }
  };

  if (printMode) {
    return value.map((option) => option.label).sort().join(', ');
  }

  const updateDatabase = (newValue) => {
    const originalMap = Array.isArray(assignmentMap) && assignmentMap.length > 0 ? assignmentMap : [];
    const originalIDs = originalMap.map((value) => value.id);
    const newIDs = newValue.map((value) => value.id);
    const sameLength = originalIDs.length === newIDs.length;

    let arraysAreIdentical = sameLength;

    if (sameLength) {
      // Each array of IDs has the same number of entries. Check if the arrays are identical.
      arraysAreIdentical = newIDs.every((id) => originalIDs.includes(id));
    }

    if (!arraysAreIdentical) {
      console.debug(`Updating the employee ${employeeID} assignment map.`);

      setLoading(true);

      const values = {
        employeeID,
        scheduleDate: scheduleDate.toISODate(),
        table: 'map_schedule_assignment',
        values: newIDs,
        token: authPackage(),
      };

      fetch(`${api.address}/schedule/update.php?action=update-tags`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.error) {
            console.error(data.errorMessage);
          }

          updateAssignmentMap(employeeID, newValue);

          setLoading(false);
        });
    }
  };

  // Highlight options as the user types.
  const renderOption = (props, option, { inputValue, selected  }) => {
    if (!option.active) {
      return null;
    }

    return (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.label}
      </li>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (!popupOpen) {
      // The options were changed, but the popup is not open. The user clicked a cross to remove an option.
      updateDatabase(newValue);
    }
  };

  const handleClose = (event, reason) => {
    setPopupOpen(false);

    updateDatabase(value);
  };

  return (
    <div style={styles.container}>
      <Backdrop open={isLoading} sx={styles.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Autocomplete
        disabled={isOlderSchedule}
        multiple
        openOnFocus
        disableCloseOnSelect
        disableListWrap
        disablePortal
        handleHomeEndKeys={false}
        getOptionLabel={(option) => `${option.label}`}
        id={`map_schedule_assignment-autocomplete-employee${employeeID}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={assignments}
        renderInput={(params) => (
          <TextField {...params} label="" variant="standard" />
        )}
        renderOption={renderOption}
        style={styles.autocomplete}
        onChange={handleChange}
        onClose ={handleClose}
        onOpen={() => setPopupOpen(true)}
        value={value}
      />
    </div>
  );
}
