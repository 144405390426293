import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import formatInput from '../../constants/autocorrect';
import api from '../../constants/api.js';
import useAuthStore from '../../hooks/useAuthStore';
import useScheduleStore from '../../hooks/useScheduleStore';
import useSiteStore from '../../hooks/useSiteStore';

function compare(a, b, type) {
  if (type === 'number') {
    return Number.parseInt(a, 10) !== Number.parseInt(b, 10);
  }

  return a.toString() !== b.toString();
}

function Wrapper({ children, columnsLength, updateField }) {
  if (updateField === 'note') {
    return (
      <TableRow tabIndex={-1}>
        <TableCell colSpan={columnsLength} size="small">
          {children}
        </TableCell>
      </TableRow>
    );
  }

  return children;
}

export default function TextInput(props) {
  const {
    employeeID,
    employeeValue,
    maxLength,
    maxWidth,
    updateField,
    valueType,
    textAlign,
    placeholder,
    columnsLength,
    autoCorrectOptions,
  } = props;

  const authPackage = useAuthStore((state) => state.getAuthPackage);
  const isOlderSchedule = useScheduleStore((state) => state.isOlderSchedule);
  const noteVisibility = useScheduleStore((state) => state.noteVisibility);
  const printMode = useSiteStore((state) => state.printMode);
  const scheduleDate = useScheduleStore((state) => state.scheduleDate);
  const setError = useScheduleStore((state) => state.setError);

  const [originalValue, setOriginalValue] = useState('');
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setValue(employeeValue === null ? '' : `${employeeValue}`.replace(/"/g, ''));
  }, [employeeValue, scheduleDate]);

  const styles = {
    container: {
      position: 'relative',
      maxWidth: maxWidth,
    },
    loading: {
      zIndex: (theme) => theme.zIndex.drawer + 1,
      position: 'absolute',
    },
    input: {
      display: printMode ? 'none' : 'flex',
    },
    inputNoBorder: {
      '&:before': {
        borderBottom: 0,
      },
      '&:after': {
        borderBottom: 0,
      },
      display: printMode ? 'none' : 'flex',
    },
  };

  if (updateField === 'note') {
    const hasValue = value !== null && value !== '';

    if (printMode && hasValue && noteVisibility) {
      return <Wrapper columnsLength={columnsLength} updateField={updateField}>{value}</Wrapper>;
    }

    if (printMode) {
      return null;
    }
  } else {
    if (printMode) {
      return value;
    }
  }

  const handleSave = () => {
    if (compare(value, originalValue, valueType)) {
      setLoading(true);

      const values = {
        employeeID,
        scheduleDate: scheduleDate.toISODate(),
        updateField,
        token: authPackage(),
      };

      values[updateField] = valueType === 'number' ? Number.parseInt(value, 10) : value.toString();

      if (updateField === 'note') {
        values[updateField] = formatInput(values[updateField], { ...autoCorrectOptions ?? {}, trailing: true });
      }

      fetch(`${api.address}/schedule/update.php?action=update-${updateField}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.error) {
            console.error(data.errorMessage);

            setError(data.errorMessage);
          }

          setLoading(false);
        });
    }
  };

  return (
    <Wrapper columnsLength={columnsLength} updateField={updateField}>
      <div style={styles.container}>
        <Backdrop open={isLoading} sx={styles.loading}>
          <CircularProgress color="inherit" size={20} />
        </Backdrop>
        <Input
          disabled={isOlderSchedule}
          value={value}
          inputProps={{ maxLength: maxLength, sx:{ maxWidth: maxWidth, textAlign: textAlign ?? 'center' } }}
          onChange={({ target }) => setValue(formatInput(target.value, autoCorrectOptions))}
          onFocus={({ target }) => setOriginalValue(target.value)}
          onBlur={handleSave}
          placeholder={placeholder ?? null}
          sx={{ fontSize: '0.875rem', ...updateField === 'note' ? styles.inputNoBorder : styles.input }}
        />
      </div>
    </Wrapper>
  );
}
