import { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { MapContainer, Marker, Polygon, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import calculateCenter from '../assignments/calculateCenter';
import api from '../../constants/api';

const defaultZoom = 12;
const personIcon = <svg style={{ color: '#5476FF' }} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon">
    <path fill="currentColor" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
  </svg>;
const iconString = ReactDOMServer.renderToString(personIcon);

const assignmentMarker = L.divIcon({
  className: "dummy",
  iconSize: [80, 60],
  html: `<div style="zoom:100%;overflow:visible;">${iconString}</div>`
});

function MapEvents({ setZoomLevel }) {
  const map = useMap();

  useMapEvents({
    zoom: () => {
      setZoomLevel(map.getZoom());
    },
  });
}

export default function Map() {
  const mapElement = useRef(null);
  const [assignments, setAssignments] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(defaultZoom);

  useEffect(() => {
    if (!mapElement.current) {
      return;
    }

    const map = L.map(mapElement.current).setView([42.2808, -83.7430], 13);
    const mapOptions = {
      maxZoom: 19,
      attribution: '© OpenStreetMap',
    };

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', mapOptions).addTo(map);
  }, []);

  useEffect(() => {
    const parsedUrl = new URL(window.location.href);
    const date = parsedUrl.searchParams.get('date');

    async function fetchRecords() {
      fetch(`${api.address}/schedule/map.php${date !== null ? `?date=${date}` : ''}`)
        .then(data => data.json())
        .then((data) => {
          setAssignments(data.body);
        });
    }

    fetchRecords();
  }, []);

  return (
    <MapContainer
      center={[32.709547875929175,-117.15734481811525]}
      zoom={defaultZoom}
      scrollWheelZoom={true}
      style={{ height: '100vh' }}
    >
      <MapEvents setZoomLevel={setZoomLevel} />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {Object.values(assignments).map((assignment, index) => {
        if (assignment.coordinates.length === 0) {
          return null;
        }

        return (
          <Polygon pathOptions={{ color: 'green' }} positions={assignment.coordinates} key={assignment.assignmentID}>
            {zoomLevel < 13 ? null : <Marker icon={assignmentMarker} position={calculateCenter(assignment.coordinates)}>
              <Popup>
                <p>{assignment.unit}P</p>
                <p>{assignment.firstname} {assignment.lastname}</p>
                <span>{assignment.assignmentDescription}</span>
              </Popup>
            </Marker>}
          </Polygon>
        );
      })}
    </MapContainer>
  );
}
