import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import L from 'leaflet';
import { MapContainer, Marker, Polygon, Popup, TileLayer, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import PermissionsGate from '../PermissionsGate';
import { SCOPES } from '../../constants/permission-maps.js';
import AddShape from './AddShape';
import coordinates from '../../constants/coordinates';
import customIcon from '../../assets/customMarker.png';

const newShapeIcon = L.icon({
  iconUrl: customIcon,
  iconSize: [10, 10],
  iconAnchor: [4, 4],
});

const shapeLabel = L.icon({
  iconUrl: customIcon,
  iconSize: [0, 0],
  iconAnchor: [8, 8],
});

function MapEvents(props) {
  const {
    assignmentCoordinates,
    assignmentID,
    editMode,
    setAssignmentCoordinates,
    flyToCoordinates,
    newAssignment,
    setZoomLevel,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);

  const map = useMap();

  useEffect(() => {
    window.onkeydown = (event) => {
      if (event.keyCode === 27 && assignmentCoordinates.length > 0) {
        setOpenDialog(true);
      } else if (event.ctrlKey && event.key === 'z') {
        setAssignmentCoordinates(assignmentCoordinates.splice(0, assignmentCoordinates.length - 1));
      }
    };

    return () => window.onkeydown = null;
  }, [assignmentCoordinates, setAssignmentCoordinates]);

  useEffect(() => {
    if (Array.isArray(flyToCoordinates) && flyToCoordinates.length === 2) {
      console.debug(`Flying to coordinates [${flyToCoordinates.join(', ')}]`);

      map.flyTo(flyToCoordinates, 14);
    }
  }, [flyToCoordinates, map]);

  useMapEvents({
    click: (event) => {
      if (typeof event.latlng === 'undefined') {
        console.error('The user clicked on the map to save a coordinate, but the event.latlng object is undefined.');
      } else if (editMode && (assignmentID !== null || newAssignment)) {
        // Update the array.
        setAssignmentCoordinates([...assignmentCoordinates, [event.latlng.lat, event.latlng.lng]]);

        console.debug(`(${assignmentCoordinates.length + 1}) Added ${event.latlng.lat}, ${event.latlng.lng}`);
      }
    },
    zoom: () => {
      setZoomLevel(map.getZoom());
    },
  });

  const handleClose = () => {
    setOpenDialog(false);

    // Clear the array.
    setAssignmentCoordinates([]);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(assignmentCoordinates));

    handleClose();
  }

  return (
    <>
      <Dialog onClose={handleClose} open={openDialog}>
        <DialogTitle>Copy these coordinates</DialogTitle>
        <Button variant="outlined" onClick={handleCopy}>Copy address</Button>
      </Dialog>
      <Polygon pathOptions={{ color: '#00FF59' }} positions={assignmentCoordinates} key="temporary-shape" />
      {assignmentCoordinates.map((position, index) => {
        return (
          <Marker
            position={position}
            key={`${index}`}
            icon={newShapeIcon}
          />
        );
      })}
    </>
  );
}

export default function OpenStreetMap(props) {
  const {
    assignments,
    setAssignments,
    editMode,
    setEditMode,
    show,
    assignment,
    clearForm,
    flyToCoordinates,
    assignmentID,
    assignmentCoordinates,
    setAssignmentCoordinates,
    newAssignment,
  } = props;

  const defaultZoom = 12;
  const [zoomLevel, setZoomLevel] = useState(defaultZoom);
  const mapElement = useRef(null);

  useEffect(() => {
    if (!mapElement.current) {
      return;
    }

    const map = L.map(mapElement.current).setView([42.2808, -83.7430], 13);
    const mapOptions = {
      maxZoom: 19,
      attribution: '© OpenStreetMap',
    };

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', mapOptions).addTo(map);
  }, []);

  const toggleEditMode = () => {
    clearForm();

    // Clear the array of coordinates.
    setAssignmentCoordinates([]);

    const newAssignments = { ...assignments };

    Object.values(newAssignments).forEach((assignment) => assignment.checked = true);

    setAssignments(newAssignments);

    setEditMode(!editMode);
  };

  return (
    <MapContainer
      center={[32.709547875929175,-117.15734481811525]}
      zoom={defaultZoom}
      scrollWheelZoom={true}
      style={{ height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <PermissionsGate scopes={[SCOPES.canCreate]}>
        <MapEvents
          editMode={editMode}
          assignmentID={assignmentID}
          assignmentCoordinates={assignmentCoordinates}
          setAssignmentCoordinates={setAssignmentCoordinates}
          assignment={assignment}
          flyToCoordinates={flyToCoordinates}
          newAssignment={newAssignment}
          setZoomLevel={setZoomLevel}
        />
        <AddShape editMode={editMode} toggleEditMode={toggleEditMode} />
      </PermissionsGate>

      {Object.values(assignments).map((assignment, index) => {
        if (!assignment.checked || !show(assignment) || assignment.coordinates.length === 0) {
          return null;
        }

        return (
          <Polygon pathOptions={{ color: 'purple' }} positions={assignment.coordinates} key={assignment.assignmentID}>
            {zoomLevel < 13 ? null : <Marker icon={shapeLabel} position={assignment.center}>
              <Tooltip direction='center' offset={[0, 0]} opacity={0.8} permanent>
                <span>{assignment.assignmentDescription}</span>
              </Tooltip>
            </Marker>}
          </Polygon>
        );
      })}

      {coordinates.markers.map((marker, index) => {
        return (
          <Marker position={marker.position} key={`${index}${marker.label}`}>
            <Popup>{marker.label}</Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
}
