import api from '../../constants/api';
import Container from '../table/Container';
import Form from './Form';

/**
 * Other properties include:
 * minWidth: 170,
 * align: 'right',
 * format: (value) => value.toLocaleString('en-US')
 */
const columns = [
  { id: 'rankDescriptionShort', label: 'Rank abbreviation' },
  { id: 'rankDescription', label: 'Rank description' },
];

function createRows(data) {
  const rows = [];

  data.forEach((row) => {
    rows.push({
      rankDescription: row.rankDescription,
      rankDescriptionShort: row.rankDescriptionShort.toUpperCase(),
      rankID: row.rankID,
    });
  });

  return rows;
}

export default function Ranks() {
  const apiAddress = {
    fetchRecords: `${api.address}/rank/read.php?action=rankTable`,
    fetchRecordAndSelectOptions: `${api.address}/rank/read.php?action=editRank&rankID=`,
  };
  const primaryKey = 'rankID';

  return (
    <Container
      apiAddress={apiAddress}
      columns={columns}
      createRows={createRows}
      primaryKey={primaryKey}
      Form={Form}
    />
  );
}
