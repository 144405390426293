import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveCalendar } from '@nivo/calendar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { ResponsiveTreeMap } from '@nivo/treemap';
import theme from './theme.json';

const defs = [
  {
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    size: 4,
    padding: 1,
    stagger: true
  },
  {
    id: 'lines',
    type: 'patternLines',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    rotation: -45,
    lineWidth: 6,
    spacing: 10
  }
];

export const Pie = ({ data }) => (
  <ResponsivePie
    colors={{ scheme: 'nivo' }}
    theme={theme}
    data={data}
    margin={{ top: 20, right: 0, bottom: 30, left: 0 }}
    innerRadius={0.4}
    padAngle={0.7}
    cornerRadius={0}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          0.2
        ]
      ]
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          2
        ]
      ]
    }}
    defs={defs}
    legends={[
      {
        anchor: 'left',
        direction: 'column',
        justify: false,
        translateX: 0,
        translateY: 0,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 30,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 26,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000'
            }
          }
        ]
      }
    ]}
  />
);

export const Bar = ({ axisBottomLegend = 'no legend', axisLeftLegend = 'no legend', data, indexBy = 'unknown index', keys = [] }) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={{ scheme: 'nivo' }}
    defs={defs}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6
        ]
      ]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: axisBottomLegend,
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: axisLeftLegend,
      legendPosition: 'middle',
      legendOffset: -40
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6
        ]
      ]
    }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
  />
);

export const TreeMap = ({ data, property }) => (
  <ResponsiveTreeMap
    data={data}
    identity="name"
    value={property}
    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
    labelSkipSize={12}
    labelTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.2
        ]
      ]
    }}
    parentLabelPosition="left"
    parentLabelTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          2
        ]
      ]
    }}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          0.1
        ]
      ]
    }}
  />
)

export const RadialBar = ({ data }) => (
  <ResponsiveRadialBar
    data={data}
    padding={0.4}
    cornerRadius={2}
    innerRadius={0.2}
    margin={{ top: 24, right: 36, bottom: 24, left: 0 }}
    radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
    circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
    enableTracks={false}
    legends={[
      {
        anchor: 'left',
        direction: 'column',
        justify: false,
        translateX: 80,
        translateY: 0,
        itemsSpacing: 6,
        itemDirection: 'left-to-right',
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: 'square',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000'
            }
          }
        ]
      }
    ]}
  />
);

export const Calendar = ({ data, from, to, tooltip = () => {} }) => (
  <ResponsiveCalendar
    data={data}
    from={from}
    to={to}
    emptyColor="#eeeeee"
    colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
    yearSpacing={40}
    monthBorderColor="#ffffff"
    dayBorderWidth={2}
    dayBorderColor="#ffffff"
    tooltip={tooltip}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'row',
        translateY: 36,
        itemCount: 4,
        itemWidth: 42,
        itemHeight: 36,
        itemsSpacing: 14,
        itemDirection: 'right-to-left'
      }
    ]}
  />
);
