import { createBrowserRouter } from 'react-router-dom';
import APIKeys from './components/apikeys';
import Assignments from './components/assignments';
import DataEmployees from './components/data/employees';
import DataStatuses from './components/data/statuses';
import ErrorPage from './components/ErrorPage';
import Employees from './components/employees';
import Layout from './components/layout';
import Map from './components/map';
import Notifications from './components/notifications';
import Ranks from './components/ranks';
import Schedule from './components/schedule';
import Squads from './components/squads';
import Users from './components/users';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'apikeys',
        element: <APIKeys />,
      },
      {
        path: 'assignments',
        element: <Assignments />,
      },
      {
        path: 'data',
        children: [
          {
            path: 'statuses',
            element: <DataStatuses />,
          },
          {
            path: 'employees',
            element: <DataEmployees />,
          },
        ],
      },
      {
        path: 'employees',
        element: <Employees />,
      },
      {
        path: 'notifications',
        element: <Notifications />,
      },
      {
        path: 'ranks',
        element: <Ranks />,
      },
      {
        path: '/',
        element: <Schedule />,
      },
      {
        path: 'schedule',
        element: <Schedule />,
      },
      {
        path: 'squads',
        element: <Squads />,
      },
      {
        path: 'users',
        element: <Users />,
      },
    ],
  },
  {
    path: 'map',
    element: <Map />,
  },
]);

export default router;
