import IconButton from '@mui/material/IconButton';
import Print from '@mui/icons-material/Print';
import Tooltip from '@mui/material/Tooltip';

export default function PrintIcon({ fontSize, onClick, sx }) {
  return (
    <Tooltip title="View print mode">
      <IconButton onClick={onClick}>
        <Print fontSize={fontSize || 'large'} sx={sx} titleAccess="Print" />
      </IconButton>
    </Tooltip>
  );
}
