import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import api from '../../constants/api';
import useAuthStore from '../../hooks/useAuthStore';

const styles = {
  loading: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
  stacks: {
    marginBottom: '1em',
    minWidth: '300px',
  },
  submitRow: {
    display: 'flex',
    marginTop: '1em',
  },
  helpContainer: {
    padding: '1em',
    position: 'relative',
    margin: '0 3em 4em 3em',
  },
  helpIconContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  helpIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    '&:hover': {
      color: (theme) => theme.palette.primary.main,
    },
  },
};

export default function Form({ setView, recordData }) {
  const authPackage = useAuthStore((state) => state.getAuthPackage);

  const [apiDescription, setApiDescription] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiUrl, setApiUrl] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const newRecord = typeof recordData === 'undefined';

  useEffect(() => {
    if (!newRecord && typeof recordData.apiKeyID !== 'undefined') {
      console.debug('Filling the existing API key data.');

      setApiDescription(recordData.apiDescription);
      setApiKey(recordData.apiKey);

      const url = new URL(window.location.href);
      setApiUrl(`${url.origin}?apikey=${apiKey}`);
    }
  }, [apiKey, recordData, newRecord]);

  const handleChangeAPIDescription = (event) => {
    setApiDescription(event.target.value);
  };

  const handleSubmit = () => {
    // Show the loading animation to prevent further values from changing.
    setLoading(true);

    // The role is set. Continue to saving this record.
    const apiAddress = newRecord ? `${api.address}/apikey/create.php` : `${api.address}/apikey/update.php`;
    const values = {
      apiDescription,
      token: authPackage(),
    };

    if (!newRecord) {
      // Add the API key ID if updating a record.
      values.apiKeyID = recordData.apiKeyID;
    }

    fetch(apiAddress, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  };

  const handleDelete = () => {
    console.debug(`The user wants to delete API key ${recordData.apiKeyID}`);

    setLoading(true);

    fetch(`${api.address}/apikey/delete.php?apiKeyID=${recordData.apiKeyID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  }

  return (
    <Paper elevation={2} sx={styles.helpContainer}>
      <Backdrop open={isLoading} sx={styles.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorMessage !== ''
        ? <>
            <Alert severity="warning" sx={{ marginBottom: '1em' }}>{errorMessage}</Alert>
            <Divider sx={{ marginTop: '1em', marginBottom: '2em' }} />
          </>
        : null}
      <Stack direction="row" spacing={2} sx={styles.stacks}>
        <TextField
          required
          id="input-apiDescription"
          label="Description"
          variant="outlined"
          value={apiDescription}
          onChange={handleChangeAPIDescription}
          fullWidth
        />
      </Stack>
      <Stack direction="row" spacing={2} sx={{ display: newRecord ? 'none' : 'flex', ...styles.stacks }}>
        <TextField
          label="Schedule"
          disabled
          value={apiUrl}
          sx={{ minWidth: '300px' }}
        />
        <Button variant="outlined" onClick={() => navigator.clipboard.writeText(apiUrl)}>Copy address</Button>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ display: newRecord ? 'none' : 'flex', ...styles.stacks }}>
        <TextField
          label="Officer map"
          disabled
          value={apiUrl.replace('?', '/map?')}
          sx={{ minWidth: '300px' }}
        />
        <Button variant="outlined" onClick={() => navigator.clipboard.writeText(apiUrl.replace('?', '/map?'))}>Copy address</Button>
      </Stack>
      <Box sx={styles.submitRow}>
        {!newRecord ? <Button variant="outlined" onClick={handleDelete} color="error">Delete</Button> : null}
        <div style={styles.helpIconContainer}>&nbsp;</div>
        <Button variant="outlined" onClick={handleSubmit}>Save</Button>
      </Box>
    </Paper>
  );
}
