// All the user roles in the application.
export const ROLES = {
  viewer: "VIEWER",
  editor: "EDITOR",
  owner: "OWNER"
};

// Scopes we pass to the gating wrapper.
export const SCOPES = {
  canCreate: "can-create",
  canEdit: "can-edit",
  canDelete: "can-delete",
  canView: "can-view"
};

// The map that defines the set of scopes each user role possesses.
export const PERMISSIONS = {
  [ROLES.viewer]: [SCOPES.canView],
  [ROLES.editor]: [SCOPES.canView, SCOPES.canEdit],
  [ROLES.owner]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete
  ]
};
