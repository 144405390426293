import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import image01 from '../../assets/notifications/01.png';
import image02 from '../../assets/notifications/02.png';
import image03 from '../../assets/notifications/03.png';
import image04 from '../../assets/notifications/04.png';
import image05 from '../../assets/notifications/05.png';
import image06 from '../../assets/notifications/06.png';
import image07 from '../../assets/notifications/07.png';

function Image({ alt, src }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
      <img src={src} alt={alt} />
    </div>
  );
}

const notifications = [
  {
    notificationID: 6,
    date: '2022-11-11',
    page: 'Navigation',
    summary: "Browser history support",
    Component: () => {
      return (
        <Box>
          <Typography paragraph>
            The site now uses your browsers' history. You can go backward and forward like you would normally.
            This also means that you can send someone a link to a specific page or bookmark a specific page for quick access.
          </Typography>
          <Image src={image07} alt="image of the refresh button" />
        </Box>
      );
    },
  },
  {
    notificationID: 5,
    date: '2022-11-09',
    page: 'Navigation',
    summary: "Refreshing the current days' schedule",
    Component: () => {
      return (
        <Box>
          <Typography paragraph>
            Users with the ownership role are able to edit all employee and squad data.
            These users may now also use the refresh button to update all of the employee and squad data in the schedule.
            This refresh is done only on the current days' schedule.
            You can always edit employees and squads without fear that the schedule will be affected.
          </Typography>
          <Typography>
            For example, if you assign an employee to a different squad todays' schedule will still show that employee in
            the previous squad. Use this new button to have that employee show up in its new current squad.
            Another example, if you change a squads' days off and want the current days' schedule to reflect that change:
            press the new refresh button to update today's schedule with that squads' new days off.
          </Typography>
          <Image src={image06} alt="image of the refresh button" />
        </Box>
      );
    },
  },
  {
    notificationID: 4,
    date: '2022-10-24',
    page: 'Schedule',
    summary: 'A place for supervisor notes',
    Component: () => {
      return (
        <Box>
          <Typography>
            Assignments are static and must represent a beat area so I have added a notes field into which a
            supervisor may add the other details of a officer's day like training, doctor appointments, split shifts,
            and other miscellaneous notes that are now no longer appropriate to be written in the assignments field.
          </Typography>
          <Image src={image04} alt="image of the notes field" />
          <Typography>
            When viewing the schedule in print mode there is an eye icon. Clicking this icon will also show the notes.
            Clicking it again will hide the notes.
          </Typography>
          <Image src={image05} alt="image of the notes field" />
        </Box>
      );
    },
  },
  {
    notificationID: 3,
    date: '2022-10-17',
    page: 'Assignments',
    summary: 'Map shape labels',
    Component: () => {
      return (
        <Box>
          <Typography>
            Assignments that have coordinates now have a label in the average middle of the resulting shape.
          </Typography>
          <Image src={image03} alt="image of the bell icon" />
        </Box>
      );
    },
  },
  {
    notificationID: 2,
    date: '2022-10-16',
    page: 'Navigation',
    summary: 'See notifications',
    Component: () => {
      return (
        <Box>
          <Typography>
            A notification bell is now in the navigation bar next to the logout icon.
            The bell will show the number of new notifications for the user.
          </Typography>
          <Image src={image02} alt="image of the bell icon" />
        </Box>
      );
    },
  },
  {
    notificationID: 1,
    date: '2022-10-16',
    page: 'Users',
    summary: 'Reset an user password',
    Component: () => {
      return (
        <Box>
          <Typography>
            It is now possible to reset an user's password. The new password will be emailed to the user.
          </Typography>
          <Image src={image01} alt="image of the reset password icon" />
        </Box>
      );
    },
  },
];

export default notifications;
