import { useNavigate } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Tooltip from '@mui/material/Tooltip';
import { notifications } from '../notifications';
import useNotificationStore from '../../hooks/useNotificationStore';

export default function NotificationIcon({ sx }) {
  const notificationID = useNotificationStore((state) => state.notificationID);
  const newCount = notifications.length - notificationID;
  const navigate = useNavigate();

  return (
    <Tooltip title="View notifications">
      <IconButton onClick={() => navigate('notifications')}>
        <Badge badgeContent={newCount} color="primary">
          <NotificationsNoneIcon fontSize="large" sx={sx} titleAccess="Notifications" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
