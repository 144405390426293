import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import api from '../../constants/api';
import AuthoritativeNotification from './AuthoritativeNotification';
import CreationNotification from './CreationNotification';
import EmployeeRow from './EmployeeRow';
import NotesIcon from './NotesIcon';
import useAuthStore from '../../hooks/useAuthStore';
import useScheduleStore from '../../hooks/useScheduleStore';
import useSiteStore from '../../hooks/useSiteStore';
import columns from '../../constants/scheduleColumns';

function buildCreationString(dates, setCreationString) {
  let creationString = '';
  let difference = '';

  if (dates.createdDate !== dates.scheduleDate) {
    // Calculate how many days ago this schedule was created.
    const start = DateTime.fromISO(dates.createdDate);
    const end = DateTime.fromISO(dates.scheduleDate);
    const { days } = end.diff(start, ['days']).toObject();

    difference = `${days} day${days > 1 ? 's' : ''} before this schedule date`;
  }

  if (dates.createdDate) {
    creationString = `This schedule was created ${difference} by ${dates.createdName} on ${dates.createdDate}.`;
  }

  if (dates.updated) {
    creationString = `${creationString} This schedule was updated by ${dates.updatedName}.`;
  }

  setCreationString(creationString);
}

export default function Schedule() {
  const setAssignments = useScheduleStore((state) => state.setAssignments);
  const setAssignmentMap = useScheduleStore((state) => state.setAssignmentMap);
  const authPackage = useAuthStore((state) => state.getAuthPackage);
  const setCreationString = useScheduleStore((state) => state.setCreationString);
  const [error, setError] = useScheduleStore((state) => [state.error, state.setError]);
  const printMode = useSiteStore((state) => state.printMode);
  const role = useAuthStore((state) => state.role);
  const [scheduleDate, setScheduleDate] = useScheduleStore((state) => [state.scheduleDate, state.setScheduleDate]);
  const [squads, setSquads] = useScheduleStore((state) => [state.squads, state.setSquads]);
  const setStatuses = useScheduleStore((state) => state.setStatuses);
  const setStatusMap = useScheduleStore((state) => state.setStatusMap);

  const [isLoading, setLoading] = useState(true);

  const styles = {
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      paddingBottom: '1em',
    },
    loading: {
      zIndex: (theme) => theme.zIndex.drawer + 1,
      position: 'absolute',
    },
    header: {
      fontSize: printMode ? '2.2rem' : '1.5rem',
      border: 0,
    },
    cellBorder: {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  };

  useEffect(() => {
    console.debug(`[${scheduleDate.toISODate()}] Building the schedule table.`);

    setLoading(true);

    async function fetchRecords() {
      fetch(`${api.address}/schedule/read.php?date=${scheduleDate.toISODate()}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: authPackage(),
        }),
      })
        .then(data => data.json())
        .then((data) => {
          if (data.error) {
            setError(data.errorMessage);
          } else {
            setError(false);

            setAssignments(data.body.assignments);
            setAssignmentMap(data.body.assignmentMap);
            setStatuses(data.body.statuses);
            setStatusMap(data.body.statusMap);
            setSquads(data.body.squads);
            buildCreationString(data.body.dates, setCreationString);
          }

          setLoading(false);
        });
    }

    fetchRecords();
  }, [scheduleDate, authPackage, setError, setAssignments, setAssignmentMap, setStatuses, setStatusMap, setSquads, setCreationString]);

  useEffect(() => {
    // The viewer role will have printMode on by default. This means the user did not click the print icon button.
    if (printMode && role !== 'VIEWER') {
      setTimeout(window.print, 500);
    }
  }, [printMode, role]);

  if (error) {
    return (
      <div style={styles.container}>
        <Backdrop open={isLoading} sx={styles.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <AuthoritativeNotification />
        <Alert severity="warning" sx={{ marginBottom: '1em' }}>{scheduleDate.toISODate()}: {error}</Alert>
      </div>
    );
  }

  function DatePicker() {
    if (role !== 'VIEWER') {
      return null;
    }

    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DesktopDatePicker
          label=""
          inputFormat="MM/DD/YYYY"
          value={scheduleDate}
          onChange={(newValue) => {
            setScheduleDate(newValue === true ? DateTime.now() : newValue);
          }}
          // Adjust the end adornment so that it is larger.
          components={{ OpenPickerIcon: () => <CalendarMonthIcon fontSize="large" /> }}
          // Remove the TextField input element. We want to show only the icon.
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <th className="no-print" ref={inputRef} sx={{ top: '17px', position: 'relative'}}>
              {InputProps?.endAdornment}
            </th>
          )}
          showDaysOutsideCurrentMonth={true}
          disableMaskedInput={true}
        />
      </LocalizationProvider>
    );
  }

  return (
    <div style={styles.container}>
      <Backdrop open={isLoading} sx={styles.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={0} sx={styles.tablePaper}>
        <AuthoritativeNotification />
        <CreationNotification />
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label="schedule table" size={printMode ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                <DatePicker />
                <NotesIcon />
                <TableCell align="center" colSpan={columns.length} style={styles.header}>
                  {typeof scheduleDate !== 'undefined' ? scheduleDate.setLocale('en-us').toLocaleString(DateTime.DATE_HUGE) : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{ minWidth: column.minWidth, maxWidth: column.maxWidth, }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(squads).map(([squadOrder, row]) => {
                if (typeof row === 'undefined') {
                  console.error(`The ${squadOrder} row is undefined.`);

                  return null;
                }

                const SquadHeader = () => {
                  if (squadOrder === '0') {
                    return null;
                  }

                  return  (
                    <TableRow>
                      <TableCell colSpan={columns.length} sx={{ fontWeight: 'bold' }}>
                        {row.stationDescription} {row.daysOff} {row.workingHours}
                      </TableCell>
                    </TableRow>
                  );
                };

                return (
                  <React.Fragment key={`squad${row.squadID}`}>
                    <SquadHeader />
                    {row?.employees.map((employee, tabindex) => {
                      return <EmployeeRow tabindex={tabindex} employee={employee} key={`employeeTableRow${employee.employeeID}`} />
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
