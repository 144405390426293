import { PERMISSIONS } from '../constants/permission-maps';
import useAuthStore from '../hooks/useAuthStore';

const hasPermission = ({ permissions, scopes }) => {
  const scopesMap = {};

  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({ children, scopes = [] }) {
  const role = useAuthStore((state) => state.role);

  const permissions = PERMISSIONS[role];

  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted) return <></>

  return <>{children}</>;
}
