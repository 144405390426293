import { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import api from '../../../constants/api';
import useAuthStore from '../../../hooks/useAuthStore';
import { useStatusDataStore } from '../../../hooks/useDataStore';
import { statuses } from '../chartInformation';
import Contents from '../contents';
import Chart1 from './chart1';
import Chart2 from './chart2';
import Chart3 from './chart3';
import Chart4 from './chart4';
import Chart5 from './chart5';
import './calculate';

const styles = {
  alert: {
    marginTop: '1rem',
  },
  divider: {
    margin: '5rem 0',
  },
};

export default function Statuses() {
  const authPackage = useAuthStore((state) => state.getAuthPackage);
  const resetChartData = useStatusDataStore((state) => state.resetChartData);
  const setConstants = useStatusDataStore((state) => state.setConstants);
  const setSourceData = useStatusDataStore((state) => state.setSourceData);

  useEffect(() => {
    resetChartData();

    console.debug('Fetching the data from the server.');

    fetch(`${api.address}/data/read.php?action=statuses`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          console.error(data.errorMessage);
        } else {
          setConstants(data.body.constants);
          setSourceData(data.body.data);
        }
      });
  }, [authPackage, resetChartData, setConstants, setSourceData]);

  return (
    <Container maxWidth={false}>
      <Alert severity="info" sx={styles.alert}>Only the previous 2 years worth of data is calculated.</Alert>
      <Contents information={statuses} />
      <Chart1 />
      <Divider sx={styles.divider} />
      <Chart2 />
      <Divider sx={styles.divider} />
      <Chart3 />
      <Divider sx={styles.divider} />
      <Chart4 />
      <Divider sx={styles.divider} />
      <Chart5 />
      <Divider sx={styles.divider} />
    </Container>
  );
}
