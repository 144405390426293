import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useStatusDataStore } from '../../../hooks/useDataStore';
import { Bar } from '../charts';
import styles from '../styles';

const sumDays = (o) => {
  const entry = { ...o };

  delete entry.employee;

  return Object.values(entry).reduce((a, b) => a + b);
}

export default function Chart4() {
  const chartData = useStatusDataStore((state) => state.chartData4);
  const abbreviationConstants = useStatusDataStore((state) => state.constants.statusAbbreviation);
  const employees = useStatusDataStore((state) => state.constants.employee);
  const setChartData = useStatusDataStore((state) => state.setChartData);
  const setFilters = useStatusDataStore((state) => state.setFilters);

  const [timeRange, setTimeRange] = useState('three-months');
  const [statusAbbreviationID, setStatusAbbreviationID] = useState(0);
  const [keys, setKeys] = useState([]);

  const statusAbbreviations = typeof abbreviationConstants !== 'undefined'
    ? Object.values(abbreviationConstants).sort((a, b) => `${a.statusAbbreviation}`.localeCompare(`${b.statusAbbreviation}`))
    : [];

  const handleChange = ({ target }, filterName) => {
    if (filterName === 'time-range') {
      setTimeRange(target.value);
    } else if (filterName === 'status-abbreviation-id') {
      setStatusAbbreviationID(target.value);
    }

    setChartData(4, []);
    setFilters(4, filterName, target.value);
  };

  useEffect(() => {
    setFilters(4, 'status-abbreviation-id', statusAbbreviationID);
  }, []);

  useEffect(() => {
    if (typeof employees !== 'undefined') {
      const statusTypes = [];

      chartData.forEach((entry) => {
        Object.keys(entry).forEach((k) => {
          if (k !== 'employee' && !statusTypes.includes(k)) {
            statusTypes.push(k);
          }
        })
      });

      setKeys(statusTypes.sort());
    }
  }, [chartData, employees]);

  const data = Object.values(chartData).sort((a, b) => sumDays(b) - sumDays(a)).slice(0, 10);

  return (
    <Container maxWidth={false} sx={styles.entry}>
      <Stack direction="row" sx={{ alignSelf: 'start' }}>
        <Typography variant="h3" id="most-split-days">
          The number of days with split-day statuses by employee
        </Typography>
        <Link href="#most-split-days">
          <IconButton aria-label="link to this chart" color="secondary">
            <LinkIcon fontSize="large" />
          </IconButton>
        </Link>
      </Stack>
      <Container maxWidth={false} sx={styles.chart}>
        {chartData.length > 0
          ? <Bar
              axisBottomLegend="employee"
              axisLeftLegend="days"
              data={data}
              indexBy="employee"
              keys={keys}
            />
          : <Skeleton variant="rectangular" width={600} height={600} />}
      </Container>
      <Paper elevation={4} sx={styles.information}>
        <FormControl>
          <InputLabel id="4-time-range-label">time range</InputLabel>
          <Select
            labelId="4-time-range-label"
            id="4-time-range"
            value={timeRange}
            label="Time range"
            onChange={(event) => handleChange(event, 'time-range')}
            sx={styles.input}
          >
            <MenuItem value="six-months">Previous 6 months</MenuItem>
            <MenuItem value="three-months">Previous 3 months</MenuItem>
            <MenuItem value="previous-month">Previous month</MenuItem>
            <MenuItem value="this-month">This month</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ marginLeft: '1rem' }}>
          <InputLabel id="4-status-abbreviation-id-label">status</InputLabel>
          <Select
            labelId="4-status-abbreviation-id-label"
            id="4-status-abbreviation-id"
            value={statusAbbreviationID}
            label="status"
            onChange={(event) => handleChange(event, 'status-abbreviation-id')}
            sx={styles.input}
          >
            <MenuItem value={0}><span style={{ fontStyle: 'italic' }}>All</span></MenuItem>
            {statusAbbreviations.map((abbreviation) => <MenuItem value={abbreviation.statusAbbreviationID} key={abbreviation.statusAbbreviationID}>{abbreviation.statusAbbreviation}</MenuItem>)}
          </Select>
        </FormControl>
      </Paper>
      <Paper elevation={4} sx={styles.information}>
        <Typography variant="body1" paragraph sx={{ fontSize: '16px' }}>
          These are the top 10 employees who have the most number of split days with a given status type.
          This chart does not calculate the number of hours. It shows the number of days where a split day status was used.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '16px' }}>
          A split day is a day where the employee worked and also had a status of less than 10 hours.
          For example, V4 is a split day because the person worked 6 hours and 4 hours of vacation.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontSize: '16px' }}>
          This chart is best used in conjunction with the next chart.
          Investigate the top few employees from this chart to determine if there is a pattern of using split day statuses.
        </Typography>
      </Paper>
    </Container>
  );
}
