import { useEffect, useState } from 'react';

export default function Create({ apiAddress, setLoading, setView, Form }) {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    console.debug('Building the new record table.');

    if (typeof apiAddress.fetchForm !== 'undefined') {
      setLoading(true);

      async function fetchForm() {
        console.debug('Fetching the form select options.');

        return fetch(apiAddress.fetchForm)
          .then(data => data.json())
          .then((data) => {
            setFormData(data.body);
            setLoading(false);
          });
      }

      fetchForm();
    }
  }, [apiAddress.fetchForm, setLoading]);

  return <Form setView={setView} formData={formData} />;
}
