import { RouterProvider } from 'react-router-dom';
import Login from './components/Login';
import router from './router';
import useAuthStore from './hooks/useAuthStore';
import './App.css';

function App() {
  const authorized = useAuthStore((state) => state.authorized);

  if (authorized === false) {
    return <Login />
  }

  return (
    <RouterProvider router={router} />
  );
}

export default App;
