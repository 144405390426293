import { DateTime } from 'luxon';
import Alert from '@mui/material/Alert';
import useScheduleStore from '../../hooks/useScheduleStore';

export default function AuthoritativeNotification() {
  const scheduleDate = useScheduleStore((state) => state.scheduleDate);

  // The date this website started being used in production.
  const liveDate = DateTime.fromISO('2022-11-09');

  if (scheduleDate >= liveDate) {
    return null;
  }

  return (
    <Alert severity="warning" sx={{ marginBottom: '1em' }}>
      {scheduleDate.toISODate()}: This schedule is not authoritative for dates before {liveDate.setLocale('en-us').toLocaleString(DateTime.DATE_HUGE)}.
      Refer to the <a href="https://archive.atomicsage.net">schedule archive</a> for dates before {liveDate.setLocale('en-us').toLocaleString(DateTime.DATE_FULL)}.
    </Alert>
  );
}
