import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../constants/api';
import Container from '../table/Container';
import Form from './Form';

/**
 * Other properties include:
 * minWidth: 170,
 * align: 'right',
 * format: (value) => value.toLocaleString('en-US')
 */
const columns = [
  { id: 'role', label: 'Role' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'squadOrder', label: 'Squad' },
  { id: 'active', label: 'Active' },
  { id: 'lastUsed', label: 'Last used' },
];

function createRows(data) {
  const rows = [];

  data.forEach((row) => {
    rows.push({
      role: row.role,
      name: `${row.lastname}, ${row.firstname}`,
      email: row.email,
      squadOrder: row.squadOrder,
      active: row.active === 1 ? <CheckIcon title="active" /> : <CloseIcon title="inactive" />,
      lastUsed: row.lastUsed,
      userID: row.userID,
    });
  });

  return rows;
}

export default function Users() {
  const apiAddress = {
    fetchRecords: `${api.address}/user/read.php?action=usersTable`,
    fetchRecordAndSelectOptions: `${api.address}/user/read.php?action=editUser&userID=`,
    fetchForm: `${api.address}/user/read.php?action=getForm`,
  };
  const primaryKey = 'userID';

  return (
    <Container apiAddress={apiAddress} columns={columns} createRows={createRows} primaryKey={primaryKey} Form={Form} />
  );
}
