import api from '../../constants/api';
import Container from '../table/Container';
import Form from './Form';

/**
 * Other properties include:
 * minWidth: 170,
 * align: 'right',
 * format: (value) => value.toLocaleString('en-US')
 */
const columns = [
  { id: 'apiDescription', label: 'Description' },
  { id: 'hitCount', label: 'Times used' },
  { id: 'lastUsed', label: 'Last used' },
];

function createRows(data) {
  const rows = [];

  data.forEach((row) => {
    rows.push({
      apiKeyID: row.apiKeyID,
      apiKey: row.apiKey,
      apiDescription: row.apiDescription,
      hitCount: row.hitCount,
      lastUsed: row.lastUsed,
    });
  });

  return rows;
}

export default function APIKeys() {
  const apiAddress = {
    fetchRecords: `${api.address}/apikey/read.php?action=getTable`,
    fetchRecordAndSelectOptions: `${api.address}/apikey/read.php?action=editKey&apiKeyID=`,
    fetchForm: `${api.address}/apikey/read.php?action=getForm`,
  };
  const primaryKey = 'apiKeyID';

  return (
    <Container
      apiAddress={apiAddress}
      columns={columns}
      createRows={createRows}
      primaryKey={primaryKey}
      Form={Form}
    />
  );
}
