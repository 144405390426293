import EditLocationIcon from '@mui/icons-material/EditLocation';
import IconButton from '@mui/material/IconButton';

export default function AddShape({ editMode, toggleEditMode }) {
  const style = {
    container: {
      position: 'absolute',
      top: '100px',
      left: '11px',
      zIndex: 1000,
      backgroundColor: '#FFF',
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      width: '30px',
      height: '30px',
      lineHeight: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      position: 'absolute',
      top: '2px',
      left: '3px',
    },
  };

  return (
    <div
      style={style.container}
      className={'leaflet-bar'}
      onClick={toggleEditMode}
    >
      <IconButton color="primary" aria-label="toggle map edit mode" component="label">
        <EditLocationIcon color={editMode ? 'primary' : 'disabled'} />
      </IconButton>
    </div>
  );
}
