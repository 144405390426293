import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import ViewListIcon from '@mui/icons-material/ViewList';
import Table from './Table';
import Create from './Create';
import Edit from './Edit';

const styles = {
  container: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    top: 0,
    alignItems: 'center',
  },
  bottomNavigation: {
    position: 'fixed',
    bottom: 0,
    width: 'auto',
    paddingLeft: '1em',
    paddingRight: '1em',
    zIndex: 2,
  },
  loading: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
};

export default function Container({ apiAddress, columns, createRows, primaryKey, Form, customEdit }) {
  const [view, setView] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [recordID, setRecordID] = useState(null);
  const [error, setError] = useState(false);

  const handleEdit = (recordID) => {
    let continueFunction = true;

    console.debug(`The user wants to edit record ${recordID}.`);

    if (typeof customEdit === 'function') {
      continueFunction = customEdit(recordID);
    }

    if (continueFunction === false) {
      return false;
    }

    setRecordID(recordID);

    // Update the bottom navigation.
    setView(2);
  };

  let pageView = <Table
    apiAddress={apiAddress}
    columns={columns}
    createRows={createRows}
    handleEdit={handleEdit}
    primaryKey={primaryKey}
    setLoading={setLoading}
    setError={setError}
  />;

  if (view === 1) {
    pageView = <Create
      setView={setView}
      setLoading={setLoading}
      Form={Form}
      apiAddress={apiAddress}
      setError={setError}
    />;
  } else if (view === 2) {
    pageView = <Edit
      setView={setView}
      setLoading={setLoading}
      Form={Form}
      apiAddress={apiAddress}
      recordID={recordID}
      setError={setError}
    />
  }

  const Nofity = () => {
    if (!error) {
      return null;
    }

    return <Alert severity="warning" sx={{ position: 'relative', width: '96%', flexShrink: 1 }}>{error}</Alert>;
  };

  return (
    <div className="outerContainer" style={styles.container}>
      <Backdrop open={isLoading} sx={styles.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="innerContainer" style={{ ...styles.innerContainer, marginTop: view === 0 ? 0 : '4rem', }}>
        <Nofity />
        {pageView}
      </div>
      <Paper sx={styles.bottomNavigation} elevation={3} square>
        <BottomNavigation
          showLabels
          value={view}
          onChange={(event, newValue) => {
            setError(false);
            setView(newValue);
          }}
        >
          <BottomNavigationAction label="List all" icon={<ViewListIcon />} />
          <BottomNavigationAction label="Add new" icon={<AddIcon />} />
          <BottomNavigationAction disabled label="Edit" icon={<EditIcon />} />
        </BottomNavigation>
      </Paper>
    </div>
  );
}
