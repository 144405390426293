import create from 'zustand';
import api from '../constants/api';
import useAuthStore from './useAuthStore';

const useNotificationStore = create((set, get) => ({
  notificationID: 0,
  getNotificationID: (userID) => {
    fetch(`${api.address}/user/read.php?action=notificationID&userID=${userID}`)
      .then(data => data.json())
      .then((data) => {
        if (typeof data.notificationID !== 'undefined') {
          get().setNotificationID(data.notificationID);
        }
      });
  },
  setNotificationID: (notificationID) => set({ notificationID }),
}));

const unsubscribe = useAuthStore.subscribe((state) => {
  if (!state.usingApiKey && state.authorized && state.userID !== '') {
    useNotificationStore.getState().getNotificationID(state.userID);

    unsubscribe();
  }
});

export default useNotificationStore;
