import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styles from './styles';

export default function Contents({ information }) {
  return (
    <Container maxWidth={false} sx={styles.entry}>
      <Paper elevation={1} sx={styles.contents}>
        <Typography variant="h5">Contents</Typography>
        <List>
          {information.map((chart) => {
            return (
              <ListItemButton key={chart.link} component="a" href={`#${chart.link}`}>
                <ListItemIcon>
                  {chart.icon}
                </ListItemIcon>
                <ListItem disablePadding>
                  <ListItemText primary={chart.name} />
                </ListItem>
              </ListItemButton>
            );
          })}
        </List>
      </Paper>
    </Container>
  );
}
