import { useEffect, useState } from 'react';

export default function Edit({ apiAddress, setLoading, setView, recordID, Form }) {
  const [recordData, setRecordData] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    console.debug('Building the record form.');

    setLoading(true);

    async function fetchUser() {
      console.debug('Fetching the record details and the form select options.');

      return fetch(`${apiAddress.fetchRecordAndSelectOptions}${recordID}`)
        .then(data => data.json())
        .then((data) => {
          setRecordData(data.body.record);
          setFormData(data.body.form);
          setLoading(false);
        });
    }

    fetchUser();
  }, [apiAddress.fetchRecordAndSelectOptions, recordID, setLoading]);

  return <Form recordData={recordData} formData={formData} setView={setView} />;
}
