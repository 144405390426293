import Alert from '@mui/material/Alert';
import useScheduleStore from '../../hooks/useScheduleStore';
import useSiteStore from '../../hooks/useSiteStore';
import PermissionsGate from '../PermissionsGate';
import { SCOPES } from '../../constants/permission-maps.js';

export default function CreationNotification() {
  const creationString = useScheduleStore((state) => state.creationString);
  const printMode = useSiteStore((state) => state.printMode);

  if (printMode || creationString.length === 0) {
    return null;
  }

  return (
    <PermissionsGate scopes={[SCOPES.canCreate]}>
      <Alert severity="info" className="no-print" sx={{ marginBottom: '1em' }}>
        Owner role message: {creationString}
      </Alert>
    </PermissionsGate>
  );
}
