import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import api from '../../constants/api';
import useAuthStore from '../../hooks/useAuthStore';

const styles = {
  helpIconContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  helpIcon: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    '&:hover': {
      color: (theme) => theme.palette.primary.main,
    },
  },
  helpContainer: {
    padding: '1em',
    position: 'relative',
    margin: '0 3em 4em 3em',
  },
  formContainer: {
    padding: '1em',
    position: 'relative',
    marginBottom: '4em',
  },
  loading: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    position: 'absolute',
  },
  stacks: {
    minWidth: '400px',
    marginBottom: '1em',
  },
  submitRow: {
    display: 'flex',
    marginTop: '1em',
  },
};

export default function Form({ setView, recordData }) {
  const authPackage = useAuthStore((state) => state.getAuthPackage);

  const newRecord = typeof recordData === 'undefined';

  const [rankDescription, setRankDescription] = useState('');
  const [rankDescriptionShort, setRankDescriptionShort] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [helpVisible, setHelpVisibility] = useState(false);

  useEffect(() => {
    if (!newRecord && typeof recordData.rankID !== 'undefined') {
      console.debug('Filling the existing rank data.');

      setRankDescription(recordData.rankDescription);
      setRankDescriptionShort(recordData.rankDescriptionShort);
    }
  }, [recordData, newRecord]);

  const handleChangeRankDescription = (event) => {
    setRankDescription(event.target.value);
  }

  const handleChangeRankDescriptionShort = (event) => {
    setRankDescriptionShort(event.target.value);
  }

  const handleSubmit = () => {
    // Show the loading animation to prevent further values from changing.
    setLoading(true);

    const apiAddress = newRecord ? `${api.address}/rank/create.php` : `${api.address}/rank/update.php`;
    const values = {
      rankDescription,
      rankDescriptionShort,
      token: authPackage(),
    };

    if (!newRecord) {
      // Add the rank ID if updating a record.
      values.rankID = recordData.rankID;
    }

    fetch(apiAddress, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  };

  const handleDelete = () => {
    console.debug(`The user wants to delete rank ${recordData.rankID}`);

    setLoading(true);

    fetch(`${api.address}/rank/delete.php?rankID=${recordData.rankID}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: authPackage(),
      }),
    })
      .then(data => data.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage(data.errorMessage);
        } else {
          setView(0);
        }

        setLoading(false);
      });
  }

  const showHelp = () => {
    setHelpVisibility(!helpVisible);
  };

  return (
    <>
      <Paper elevation={2} sx={styles.formContainer}>
        <Backdrop open={isLoading} sx={styles.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {errorMessage !== '' ? <Alert severity="warning" sx={{ marginBottom: '1em' }}>{errorMessage}</Alert> : null}
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            fullWidth
            required
            id="input-firstname"
            label="Rank abbreviation"
            variant="outlined"
            value={rankDescriptionShort}
            onChange={handleChangeRankDescriptionShort}
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={styles.stacks}>
          <TextField
            fullWidth
            required
            id="input-lastname"
            label="Rank description"
            variant="outlined"
            value={rankDescription}
            onChange={handleChangeRankDescription}
          />
        </Stack>
        <Box sx={styles.submitRow}>
          {!newRecord ? <Button variant="outlined" onClick={handleDelete} color="error">Delete</Button> : null}
          <div style={styles.helpIconContainer}>
            <Tooltip title="View help">
              <HelpOutlineIcon onClick={showHelp} fontSize="large" sx={styles.helpIcon} alt="Open help" />
            </Tooltip>
          </div>
          <Button variant="outlined" onClick={handleSubmit}>Save</Button>
        </Box>
      </Paper>
      <Paper elevation={2} sx={styles.helpContainer} style={{ display: helpVisible ? 'block' : 'none' }}>
        <Typography variant="h5">Rank abbreviation</Typography>
        <Typography variant="body1" paragraph>
          The abbreviation of the employee's rank.
          This should be all upper-case, but it's okay to type lower-case; it will be changed after saving.
        </Typography>
        <Typography variant="h5">Rank description</Typography>
        <Typography variant="body1" paragraph>
          The full English words of the rank. This will be changed to lower case after saving.
        </Typography>
      </Paper>
    </>
  );
}
